import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Text, Link, Grid, Button } from 'basis';
import { submitHandler } from 'utils';
import {
  LayoutPage,
  LayoutForm,
  Form,
  Error,
  FormState,
  FormData,
  OneTimeCode,
  PageTitle,
  LayoutSection,
} from 'components';
import { useOktaClient, useAppContext, useTrackingError, useTrackingEvent } from 'hooks';
import { DEV_FEATURES } from '__dev';
import { ApiError, ErrorType, Routes } from 'types';
import { setVerification, setError } from 'context';
import styled from 'theme/styled';

export const VERIFICATION_LOCKED_TITLE = 'Unable to verify your identity';

const RESEND_MIN = 0.5;
const FORM_ID = 'verification';
const initialValues = {
  verificationCode: '',
};

export const Verification: React.FC = () => {
  const { state, dispatch } = useAppContext();
  const history = useHistory();
  const { trackEvent } = useTrackingEvent();
  const { trackError } = useTrackingError();

  const onStartAgainClick = () => {
    trackEvent({
      category: 'application',
      action: 'application-navigation',
      location: 'verify',
      label: 'back',
    });
  };

  const onResendCodeClick = () => {
    trackEvent({
      category: 'application',
      action: 'application-navigation',
      location: 'verify',
      label: 'resend code',
    });
  };

  const onResendCodeError = (error: ApiError) => {
    trackError({ error });
  };

  const onOTPSuccess = () => {
    if (!state.assessmentId && state.correlationId) {
      history.push(Routes.acceptance);
    } else {
      history.push(Routes.main);
    }
  };

  const handleVerifyOTPSuccess = () => {
    onOTPSuccess();
  };

  const handleVerifyOTPError = (error: ApiError) => {
    if (error.type === ErrorType.OKTA_VERIFICATION_LOCKED_ERROR) {
      dispatch(setVerification({ verificationLocked: true }));
      trackError({ error, location: VERIFICATION_LOCKED_TITLE });
    } else {
      dispatch(setError(error));
      trackError({ error });
    }
  };

  const { loading, verifyOTP, applySyntheticToken } = useOktaClient({
    onSuccess: handleVerifyOTPSuccess,
    onError: handleVerifyOTPError,
  });

  const onSubmit = ({ values, errors, setErrors }: FormState): void => {
    async function verifyOTPAndRedirect() {
      if (DEV_FEATURES.OTP_SKIP) {
        const success = await applySyntheticToken();
        success && onOTPSuccess();
      } else {
        const verifyOTPResponse = await verifyOTP(values.verificationCode);
        if (typeof verifyOTPResponse === 'object' && verifyOTPResponse?.errorMessage) {
          setErrors({ verificationCode: verifyOTPResponse.errorMessage });
        }
      }
    }

    trackEvent({
      category: 'application',
      action: 'application-navigation',
      location: 'verify',
      label: 'Verify',
    });

    submitHandler({ submit: verifyOTPAndRedirect, errors });
  };

  if (state.error) {
    return (
      <LayoutPage step="login">
        <Error />
      </LayoutPage>
    );
  }

  if (state.verification.verificationLocked) {
    return (
      <LayoutPage step="login">
        <PageTitle>VERIFICATION_LOCKED_TITLE</PageTitle>
        <LayoutSection>
          <Container>
            <Text>
              <strong>You have exceeded the maximum attempts allowed.</strong>
            </Text>
          </Container>
          <Container>
            <Text>As a result, we're unable to process your request at this time.</Text>
          </Container>
          <Container padding="8 0 0 0">
            <Link
              appearance="primary-button"
              variant="blue-button"
              href={Routes.init}
              newTab={false}
              onClick={onStartAgainClick}
            >
              Restart application
            </Link>
          </Container>
        </LayoutSection>
      </LayoutPage>
    );
  }

  const factorType = state.verification.attemptingFactorType || 'sms';

  return (
    <LayoutPage step="login">
      <PageTitle>Verify your {factorType === 'sms' ? 'mobile' : 'email'}</PageTitle>
      <Form id={FORM_ID} initialValues={initialValues} onSubmit={onSubmit} loading={loading} submitButtonLabel="Verify" hideFormButtons>
        {(formData: FormData) => {
          return (
            <LayoutForm>
              <OneTimeCode
                name="verificationCode"
                label="Verification code"
                codeLength={6}
                resendMsecs={RESEND_MIN * 60000}
                subject={state.verification.maskedMobileNumber || state.contactDetails.emailAddress}
                factorType={factorType}
                onResendCodeClick={onResendCodeClick}
                onResendCodeError={onResendCodeError}
                formData={formData}
              />
              <VerifyButton loading={loading} />
            </LayoutForm>
          );
        }}
      </Form>
    </LayoutPage>
  );
};

function SecureIcon({ size = 24, color = 'white' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size - 1} ${size}`} fill="none">
      <g clipPath="url(#clip0_13377_581)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.49048 9.96875V8.77691C9.49252 8.11209 9.75779 7.47515 10.2282 7.00542C10.6987 6.53569 11.3361 6.27141 12.0009 6.27039L12.0032 6.27039C12.6673 6.27243 13.3037 6.53716 13.7733 7.00678C14.2429 7.47641 14.5076 8.11277 14.5097 8.77691L14.5097 8.77844L14.5097 9.96875H14.92C15.516 9.96875 16 10.4528 16 11.0487V15.8898C16 16.4848 15.516 16.9688 14.92 16.9688H9.079C8.484 16.9688 8 16.4848 8 15.8898V11.0487C8 10.4528 8.484 9.96875 9.079 9.96875H9.49048ZM13.5097 9.96875V8.77926C13.5083 8.37953 13.3489 7.99656 13.0662 7.71389C12.7836 7.43132 12.4008 7.27192 12.0012 7.27039C11.6013 7.27131 11.2179 7.43044 10.9348 7.71307C10.6516 7.99581 10.4919 8.37913 10.4905 8.77927V9.96875H13.5097Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2674 0.325361C11.457 0.117905 11.7252 -0.000195015 12.0063 2.41732e-07C12.2867 0.000196051 12.5542 0.118148 12.7434 0.325019L12.7441 0.32576L12.7462 0.328003L12.7657 0.348566C12.7848 0.368512 12.816 0.400645 12.8593 0.44344C12.9458 0.529064 13.0801 0.657147 13.2608 0.815537C13.6224 1.13261 14.1674 1.56925 14.8843 2.02946C16.3192 2.95054 18.4283 3.95729 21.127 4.30276C21.6261 4.36665 22 4.79147 22 5.29466V14.9451C22 14.9798 21.9977 15.0143 21.9948 15.0488C21.9931 15.0675 21.9908 15.0907 21.9874 15.1182C21.9808 15.1733 21.9703 15.2456 21.9539 15.3337C21.9212 15.51 21.8648 15.7492 21.768 16.0392C21.574 16.6205 21.2197 17.3997 20.5756 18.2796C19.291 20.0348 16.8946 22.1365 12.4459 23.8923C12.2007 24.0163 11.9056 24.0392 11.6296 23.9311C7.12937 22.1679 4.71295 20.0518 3.42174 18.2864C2.77819 17.4065 2.42445 16.6274 2.23095 16.0461C2.13442 15.7561 2.07826 15.5169 2.04567 15.3406C2.02938 15.2525 2.01899 15.1802 2.01238 15.1251C2.00908 15.0976 2.00672 15.0744 2.00505 15.0557L2.00301 15.031L2.00204 15.017C2.00097 14.9999 2 14.9756 2 14.9532V5.3C2 4.79683 2.37387 4.37202 2.87296 4.3081C5.57013 3.96267 7.6821 2.95466 9.12046 2.03197C9.83909 1.57097 10.3859 1.13352 10.7489 0.815798C10.9302 0.657082 11.0651 0.528714 11.1521 0.442868C11.1956 0.399961 11.227 0.367732 11.2462 0.34771L11.2658 0.327045L11.2674 0.325361ZM4 14.9013C4.00229 14.9181 4.00615 14.9436 4.01234 14.9771C4.02905 15.0674 4.06277 15.2168 4.12856 15.4144C4.25972 15.8084 4.52043 16.4007 5.03604 17.1057C6.03262 18.4682 8.02115 20.3044 11.9888 21.9209C15.9682 20.3037 17.9624 18.4637 18.9617 17.0984C19.4779 16.3932 19.7392 15.8004 19.8708 15.4061C19.9369 15.2082 19.9708 15.0587 19.9876 14.9682C19.9938 14.9349 19.9977 14.9095 20 14.8927V6.15189C17.358 5.67423 15.2693 4.65322 13.8039 3.71253C13.0309 3.21627 12.4281 2.74039 12.0049 2.374C11.5806 2.74093 10.9758 3.21793 10.2003 3.71537C8.7325 4.65698 6.64141 5.6792 4 6.15714V14.9013Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_13377_581">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};


const VerifyButton = ({ loading }: { loading: boolean }) => (
  <Grid colsGap="10" cols="2">
    <Grid.Item colSpan="all" colSpan-sm="0" alignItems="center">
      <Grid>
        <Button testId="submit-button" type="submit" loading={loading}>
          <IconAndText>
            <SecureIcon color="white" />
            Verify
          </IconAndText>
        </Button>
      </Grid>
    </Grid.Item>
  </Grid>
);

const IconAndText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    margin-right: 8px;
    margin-top: -2px;
  }
`;
