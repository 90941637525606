import { Box } from '@chakra-ui/react';

export function LfsLockIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="44" viewBox="0 0 34 44" fill="none">
      <path
        d="M6.44446 30.7299C5.06935 27.7503 3.92343 21.3328 5.06935 18.1241L3.46506 17.4365L2.08995 18.1241L0.714844 19.9577V40.8145L2.08995 43.1064H19.7372C11.4865 40.8145 6.44446 30.7299 6.44446 30.7299Z"
        fill="#A3DDFF"
      />
      <path
        d="M6.26451 17.5154V11.0063C6.32192 8.2572 7.45425 5.64011 9.41865 3.71622C11.3831 1.79232 14.0231 0.714844 16.7726 0.714844C19.5222 0.714844 22.1622 1.79232 24.1266 3.71622C26.091 5.64011 27.2233 8.2572 27.2807 11.0063V17.5154M16.8477 35.3324V29.8318M10.3661 17.6136V11.4738C10.3661 10.6311 10.5321 9.79658 10.8546 9.01797C11.177 8.23937 11.6497 7.53191 12.2456 6.936C12.8415 6.34008 13.5489 5.86737 14.3275 5.54486C15.1061 5.22235 15.9405 5.05636 16.7832 5.05636C17.626 5.05636 18.4604 5.22235 19.239 5.54486C20.0176 5.86737 20.725 6.34008 21.3209 6.936C21.9168 7.53191 22.3894 8.23937 22.7119 9.01797C23.0344 9.79658 23.2004 10.6311 23.2004 11.4738V17.6136M3.58225 17.6136H30.1081C31.627 17.6136 32.8583 18.8449 32.8583 20.3639V40.4873C32.8583 42.0062 31.627 43.2376 30.1081 43.2376H3.58225C2.06334 43.2376 0.832031 42.0062 0.832031 40.4873V20.3639C0.832031 18.8449 2.06334 17.6136 3.58225 17.6136ZM18.6843 27.9976C18.6843 29.0103 17.8634 29.8312 16.8508 29.8312C15.8382 29.8312 15.0173 29.0103 15.0173 27.9976C15.0173 26.985 15.8382 26.1641 16.8508 26.1641C17.8634 26.1641 18.6843 26.985 18.6843 27.9976Z"
        stroke="#414141"
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MoneyEnvelopeIcon({ size = 48 }: { size?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48" fill="none">
      <path d="M7.8125 36.0391C6.125 34.1016 4.375 29.8516 2.6875 20.2266L1.625 16.9766L0.5 17.8516V28.1016L1 45.1018L2.375 46.4518H32.75C11.3125 43.3516 7.8125 36.0391 7.8125 36.0391Z" fill="#FFC9E4" />
      <path d="M4.36719 20.4795V11.7409C4.36719 11.4387 4.48722 11.1489 4.70089 10.9353C4.91455 10.7216 5.20434 10.6016 5.50651 10.6016H41.9649C42.267 10.6016 42.5568 10.7216 42.7705 10.9353C42.9842 11.1489 43.1042 11.4387 43.1042 11.7409V20.5763M4.37256 15.0273L1.07422 17.7503M37.8718 10.6011L28.1419 2.55744C26.9183 1.55053 25.3829 1 23.7983 1C22.2136 1 20.6782 1.55053 19.4546 2.55744L9.73047 10.6011M46.5234 17.7515L43.1055 14.9316M24.1338 15.0276V16.8686M26.6335 19.0051C26.6335 19.0051 26.6335 17.0432 24.1373 17.0432C21.6411 17.0432 21.5752 19.5687 21.5752 19.5687C21.5752 19.5687 21.5752 22.1674 24.097 22.1674C26.6298 22.1674 26.6225 24.7697 26.6225 24.7697C26.6225 24.7697 26.6225 27.2915 24.0604 27.2915C21.4984 27.2915 21.6118 25.3333 21.6118 25.3333M24.1338 27.6018V29.4429M19.4572 32.9391L1.07422 17.752V44.1729C1.07422 44.7772 1.31429 45.3568 1.74162 45.7841C2.16895 46.2115 2.74853 46.4515 3.35287 46.4515H44.2489C44.8532 46.4515 45.4328 46.2115 45.8601 45.7841C46.2875 45.3568 46.5275 44.7772 46.5275 44.1729V17.752L28.1446 32.9391C26.9209 33.946 25.3855 34.4966 23.8009 34.4966C22.2162 34.4966 20.6808 33.946 19.4572 32.9391Z" stroke="#414141" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export function MoneyDocumentsIcon({ width = 35, height = 44 }: { width?: number, height?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 35 44" fill="none">
      <path
        d="M7.85742 4.54297V8.1144V8.47154L29.8217 8.91797V39.7215H31.8753L34.1074 6.15011L7.85742 4.54297Z"
        fill="#DAD1FA"
      />
      <path
        d="M3.53337 34.618L0.714844 34.4217L3.02511 0.714844L29.2428 2.50915L29.0272 5.63571M7.59263 8.51127L7.88912 4.18722L34.1068 5.98153L31.7965 39.6922L29.8213 39.5574M7.64286 31.0957H25.7053M7.64286 34.4397H25.7053M7.64286 37.7866H25.7053M16.6979 13.7697V15.3079M18.7857 16.9467C18.7857 16.9467 18.7857 15.3076 16.7001 15.3076C14.6145 15.3076 14.5595 17.4176 14.5595 17.4176C14.5595 17.4176 14.5595 19.5889 16.6665 19.5889C18.7826 19.5889 18.7765 21.7631 18.7765 21.7631C18.7765 21.7631 18.7765 23.8701 16.6359 23.8701C14.4952 23.8701 14.59 22.2341 14.59 22.2341M16.6979 23.869V25.4072M3.53292 8.51339H29.8122V42.3011H3.53292V8.51339Z"
        stroke="#414141"
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronRightIcon({ color = '#0061EE', width = 12, height = 20, ...props }) {
  return (
    <Box {...props}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.378831 0.293044C0.769355 -0.09748 1.40252 -0.09748 1.79304 0.293044L10.7945 9.29451C11.185 9.68504 11.185 10.3182 10.7945 10.7087L1.79304 19.7102C1.40252 20.1007 0.769355 20.1007 0.378831 19.7102C-0.0116939 19.3197 -0.0116939 18.6865 0.378831 18.296L8.67319 10.0016L0.378831 1.70726C-0.0116936 1.31673 -0.0116935 0.683569 0.378831 0.293044Z"
          fill={color}
        />
      </svg>
    </Box>
  );
}
