import initTealiumTracker from 'tealium-tracker';

import { TrackingEventData, getDataLayer } from '../utils/analyticsUtils';

const tealiumTracker = initTealiumTracker({
  debugMode: localStorage.getItem('tealiumDebugMode') === 'true',
});

const siteData = {
  page: {
    site: 'poi-funnel',
    siteCountry: 'nz',
    siteSubSection: 'poi-process',
  },
};

export const trackingConfig = {
  siteData,
  eventTracking: {
    trackEvent: (data: TrackingEventData) => {
      tealiumTracker.trackEvent(getDataLayer(data));
    },
    getQueryString: (data: TrackingEventData) => {
      const { pageData, eventData } = data;
      const details = {
        site: siteData?.page.site ?? '',
        siteSection: pageData?.page.siteSection ?? '',
        siteSubSection: siteData?.page.siteSubSection ?? '',
        pageTitle: pageData?.page.pageTitle ?? '',
        buttonName: eventData?.event.label ?? '',
        location: eventData?.event.location ?? '',
      } as { [key: string]: string };

      return `gemid2=${Object.keys(details)
        .filter((key) => !!details[key])
        .map((key) => encodeURIComponent(details[key]))
        .join(':')}`;
    },
  },
  pageTracking: {
    trackPageView: (data: TrackingEventData) => {
      tealiumTracker.trackPageLoad(getDataLayer(data));
    },
  },
};
