import { useCallback } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { ErrorType, ApiError, ApiType } from 'types';

type UseHandleApiErrors = {
  handleError: (error: any, doOnError?: boolean) => ApiError;
};

export const useHandleApiErrors = (api: ApiType, onError?: (error: ApiError) => void): UseHandleApiErrors => {
  const callback = useCallback(
    (error: ApiError, doOnError?: boolean) => {
      if (process.env.REACT_APP_ENV === 'dev') {
        // eslint-disable-next-line
        console.warn(error);
      }
      datadogRum.addError(error, undefined);
      doOnError && onError && onError(error);
    },
    [onError],
  );

  const handleError = useCallback(
    (error: any, doOnError?: boolean): ApiError => {
      let err = { type: ErrorType.UNKNOWN_API_ERROR, error };

      if (error) {
        if (error.type) {
          err = error;
        } else {
          if (api === ApiType.apollo) {
            if (error.graphQLErrors?.length) {
              err = { type: ErrorType.APOLLO_GRAPHQL_ERROR, error };
            } else if (Object.keys(error.networkError || {}).length) {
              err = { type: ErrorType.APOLLO_NETWORK_ERROR, error };
            } else {
              err = { type: ErrorType.APOLLO_UNKNOWN_ERROR, error };
            }
          }

          if (api === ApiType.axios) {
            if (error.response) {
              // The request was made and the server responded with a status code that falls out of the range of 2xx
              err = { type: ErrorType.AXIOS_NETWORK_ERROR, error };
            } else if (error.request) {
              // The request was made but no response was received
              err = { type: ErrorType.AXIOS_NO_RESPONSE_ERROR, error };
            } else {
              // Something happened in setting up the request that triggered an Error
              err = { type: ErrorType.AXIOS_UNKNOWN_ERROR, error };
            }
          }

          if (api === ApiType.okta) {
            err = { type: ErrorType.OKTA_ERROR, error };
          }
        }
      }

      callback(err, doOnError ?? true);
      return err;
    },
    [api, callback],
  );

  return {
    handleError,
  };
};
