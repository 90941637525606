import { useState } from 'react';
import jwtDecode from 'jwt-decode';
import { oktaAuthClient, getLatitudeId, findOTPFactor, toDomesticMobileNumber } from 'utils';
import { useAppContext } from './useAppContext';
import { useHandleApiErrors } from './useHandleApiErrors';
import { useGetUserInfo } from './useGetUserInfo';
import { setAuth, setVerification } from 'context';
import {
  ApiError,
  ErrorType,
  ApiType,
  OKTA_ERROR_CODE_INVALID_PASSCODE_FOR_INCORRECT_OTP,
  OKTA_ERROR_CODE_INVALID_TOKEN_FOR_EXPIRED_OTP,
  FactorType,
} from 'types';
import { AuthTransaction } from '@okta/okta-auth-js/lib/tx';
import { TokenResponse, APIError } from '@okta/okta-auth-js/lib/types/api';

export const RESPONSE_TYPE = ['token', 'id_token'];
export const SCOPES = ['openid', 'profile', 'lfs:cards:apply', 'phone', 'email'];

type LoginAndSendOTP = (props: { email: string; factorType: FactorType; clearToken?: boolean }) => Promise<boolean>;

type UseOktaClient = {
  loading: boolean;
  loginAndSendOTP: LoginAndSendOTP;
  verifyOTP: (passCode: string) => Promise<boolean | { errorMessage: string }>;
  resendOTP: () => Promise<boolean>;
  applySyntheticToken: () => Promise<boolean>;
};

type UseOktaClientArgs = {
  onSuccess?: () => void;
  onError?: (error: ApiError) => void;
};

export const useOktaClient = ({ onSuccess, onError }: UseOktaClientArgs = {}): UseOktaClient => {
  const { state, dispatch, updateState } = useAppContext();
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiErrors(ApiType.okta, onError);
  const { getUserInfo } = useGetUserInfo({ onError });

  const sendOTP = async (authTxn: AuthTransaction, factorType: FactorType): Promise<void> => {
    const targetFactor = findOTPFactor(authTxn, factorType);
    await targetFactor.verify();
    dispatch(setVerification({ oktaAuthTxn: authTxn }));
  };

  const loginAndSendOTP: LoginAndSendOTP = async ({ email, factorType, clearToken = true }) => {
    try {
      setLoading(true);

      updateState({
        verification: {
          attemptingFactorType: factorType,
          oktaAuthTxn: null,
        },
      });

      if (clearToken) {
        // in case browser had a cached token, clear it
        oktaAuthClient.tokenManager.clear();
        oktaAuthClient.closeSession();
      }

      const authTxn = await oktaAuthClient.signInWithCredentials({ username: email, password: '' });
      await sendOTP(authTxn, factorType);
      setLoading(false);
      onSuccess && onSuccess();
      return true;
    } catch (error) {
      setLoading(false);
      handleError({ type: ErrorType.OKTA_LOGIN_AND_SEND_OTP_ERROR, error });
      return false;
    }
  };

  const resendOTP = () => {
    return loginAndSendOTP({ email: state.contactDetails.emailAddress, factorType: state.verification.attemptingFactorType as FactorType });
  };

  const applySyntheticToken = async (): Promise<boolean> => {
    const token = process.env.REACT_APP_OKTA_TOKEN as string;
    if (!token) {
      // eslint-disable-next-line
      process.env.REACT_APP_ENV === 'dev' && console.warn('There is no synthetic token present');
      dispatch(setAuth({ latitudeId: 'mock_latitudeId', accessToken: 'mock_token' }));
      return true;
    }
    try {
      const decoded = jwtDecode(token);
      oktaAuthClient.tokenManager.setTokens({
        accessToken: {
          accessToken: token,
          // @ts-ignore
          claims: decoded,
        },
      });

      const latitudeId = getLatitudeId();
      dispatch(setAuth({ latitudeId, accessToken: token }));
      return true;
    } catch (error) {
      handleError({ type: ErrorType.OKTA_SYNTHETIC_TOKEN_ERROR, error });
      return false;
    }
  };

  const getAndSetAccessToken = async (authTxn: AuthTransaction): Promise<void> => {
    const result = await oktaAuthClient.token.getWithoutPrompt({
      sessionToken: authTxn.sessionToken,
      responseType: RESPONSE_TYPE,
      scopes: SCOPES,
    });

    const { tokens } = result as TokenResponse;
    oktaAuthClient.tokenManager.setTokens(tokens);

    // confirm the user mobile number that OTP was sent to
    const userInfo = await getUserInfo(tokens?.accessToken?.accessToken || '');
    const { mobile_phone: mobilePhone = '' } = userInfo;
    const mobileNumber = toDomesticMobileNumber(mobilePhone, state.countryCode);

    const latitudeId = getLatitudeId();
    const accessToken = oktaAuthClient.getAccessToken();

    dispatch(setAuth({ latitudeId, mobileNumber, accessToken }));
    clearVerificationHistory();
  };

  const clearVerificationHistory = () => {
    updateState({
      error: null,
      verification: {
        oktaAuthTxn: null,
        attemptingFactorType: null,
      },
    });
  };

  const verifyOTP = async (passCode: string): Promise<boolean | { errorMessage: string }> => {
    try {
      if (!state.verification.oktaAuthTxn) {
        throw new Error('No auth transaction present');
      }
      setLoading(true);
      const targetFactor = findOTPFactor(state.verification.oktaAuthTxn, state.verification.attemptingFactorType as FactorType);
      const authTxn = await targetFactor.verify({
        passCode,
      });
      await getAndSetAccessToken(authTxn);
      setLoading(false);
      onSuccess && onSuccess();
      return true;
    } catch (error) {
      setLoading(false);

      // this error is NOT set as state.applicationErrors and the returned errorMessage is rendered to the verification input field
      if (
        (error as APIError).errorCode === OKTA_ERROR_CODE_INVALID_PASSCODE_FOR_INCORRECT_OTP ||
        (error as APIError).errorCode === OKTA_ERROR_CODE_INVALID_TOKEN_FOR_EXPIRED_OTP
      ) {
        handleError({ type: ErrorType.OKTA_VERIFICATION_CODE_INCORRECT_ERROR, error }, false);
        return {
          errorMessage:
            'The verification code is incorrect. Please check the code, or resend a new code and try again.',
        };
      }

      if ((error as APIError).errorCode === 'E0000069') {
        handleError({ type: ErrorType.OKTA_VERIFICATION_LOCKED_ERROR, error });
        return false;
      }

      handleError({ type: ErrorType.OKTA_VERIFICATION_ERROR, error });
      return false;
    }
  };

  return {
    loading,
    loginAndSendOTP,
    verifyOTP,
    resendOTP,
    applySyntheticToken,
  };
};

/*
  const TokenResponse = {
    tokens: {
      accessToken: {
        accessToken: 'accessToken',
        claims: {
          sub: 'accessToken_sub'
          // etc...
        },
        tokenType: 'tokenType',
        userinfoUrl: 'userinfoUrl',
      },
      idToken: {
        idToken: 'accessToken',
        claims: {
          sub: 'idToken_sub'
          // etc...
        },
        issuer: 'issuer',
        clientId: 'clientId',
      },
      refreshToken: {
        refreshToken: 'refreshToken',
        tokenUrl: 'tokenUrl',
        issuer: 'issuer',
      },
    },
    state: 'state',
    code: 'code',
  }
 */
