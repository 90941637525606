import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// TODO: check if this works
const RouterScrollManager: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  useEffect(() => {
    ref.current && window.scrollTo(0, ref.current.scrollTop);
  }, [pathname]);

  // return <div ref={ref} />;
  return null;
};

export { RouterScrollManager };
