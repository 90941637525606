import { useTheme as useEmotionTheme } from 'emotion-theming';
import { defaultTheme as basisTheme } from 'basis';
// @ts-ignore
import { getPath } from 'basis/dist/utils/objectPath';
import { listTheme } from './list';

export const media = {
  xsMobile: `(min-width: ${basisTheme.breakpoints.xs})`,
  mobile: `(min-width: ${basisTheme.breakpoints.sm})`,
  tablet: `(min-width: ${basisTheme.breakpoints.md})`,
  laptop: `(min-width: ${basisTheme.breakpoints.lg})`,
  desktop: `(min-width: ${basisTheme.breakpoints.xl})`,
};

type Media = typeof media;

type TextStyleMode = 'self' | 'self-bold' | 'container';
interface TextStyleProps {
  name: string;
  mode: TextStyleMode;
}
interface TextStyle {
  [key: string]: any; // Adjust this based on your actual text style properties
}

export type Theme = {
  headingColor: string;
  headingSize: string;
  highlightColor: string;
  outcomeBackgroundColor: string;
  outcomeBorderColor: string;
  media: Media;
  colors?: { [key: string]: any }; // Adjust this based on your color structure
  textStyles?: { [key: string]: TextStyle };
};

const defaultTheme: Partial<Theme> = {
  headingColor: 'highlight.blue.t100',
  headingSize: 'heading4',
  outcomeBackgroundColor: '#eff7ff',
  outcomeBorderColor: '#d8edff',
  highlightColor: 'secondary.lightBlue.t25',
  media,
};

export const useTheme = (): Theme => useEmotionTheme<Theme>();

const baseTheme = {
  ...basisTheme,
  ...defaultTheme,
  media,
}

function getTheme(): Theme {
  const helpers = {
    getColor: (color: string) => {
      return getPath(baseTheme.colors, color);
    },
    getTextStyle: ({ name, mode }: TextStyleProps) => {
      switch (mode) {
        case 'self': {
          return baseTheme.textStyles?.[name];
        }

        case 'self-bold': {
          return {
            ...baseTheme.textStyles?.[name],
            ...baseTheme.textStyles?.[`${name}.bold`],
          };
        }

        case 'container': {
          const boldCSS = baseTheme.textStyles?.[`${name}.bold`];

          return {
            ...baseTheme.textStyles?.[name],
            ...(boldCSS && {
              '& strong': boldCSS,
              '& b': boldCSS,
            }),
          };
        }

        default: {
          return null;
        }
      }
    },
  };


  return {
    ...baseTheme,
    list: listTheme(baseTheme, helpers),
  } as Theme;
}

export const theme: Theme = getTheme();
