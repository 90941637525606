import { DocUploadProps, UploadMethods } from 'doc-upload';
import { getAuthHeader } from 'utils';
import { getEnv } from 'hooks/useEnv';
import { State } from 'types';
import { FormData } from 'components';
import { DocumentTypeInput } from './Fields';

export type StateNeeded = Pick<State, 'appNum' | 'countryCode' | 'source' | 'method' | 'channel'>;

export type ToDocUploadProps = (
  arg: {
    state: StateNeeded,
    formData: FormData,
    methodsRef: React.MutableRefObject<UploadMethods | undefined>
  }
) => DocUploadProps;

export const toDocUploadProps: ToDocUploadProps = ({ state, formData, methodsRef }) => {

  return {
    setAuthorizationHeaderValue,
    ...referenceFields(state, formData),
    ...documentFields(state, formData),
    ...businessFields(state, formData),
    onEvent: generateOnEvent(methodsRef),
    environment: process.env.REACT_APP_ENV === 'prod' ? 'production' :
      process.env.REACT_APP_ENV === 'dev' ? 'development' :
        'test',
    region: state.countryCode,
  };
}

async function setAuthorizationHeaderValue() {
  const overrideOktaToken = (getEnv('REACT_APP_ENV') === 'dev' && getEnv('REACT_APP_OKTA_TOKEN')) || undefined;
  const authHeader = getAuthHeader(overrideOktaToken);
  if (!authHeader) {
    throw new Error('Missing authorization header');
  }
  return authHeader;
}

function generateOnEvent(methodsRef: React.MutableRefObject<UploadMethods | undefined>): DocUploadProps['onEvent'] {
  return (evt) => {
    switch (evt.type) {
      case 'INIT':
        methodsRef.current = {
          startUpload: evt.data.startUpload,
        };
        break;
      default:
        break;
    }
  };
}

function referenceFields(state: StateNeeded, formData: FormData): Pick<DocUploadProps, 'reference' | 'referenceType'> {
  const referenceInput: string | undefined = formData?.state?.values?.reference;
  const reference = state.appNum || referenceInput || '';
  const referenceType = reference?.length === 13 ? 'APPLICATION_ID' as const : 'ACCOUNT_NUMBER' as const;

  return {
    reference,
    referenceType,
  };
}

function documentFields(state: StateNeeded, formData: FormData): Pick<DocUploadProps, 'documentType' | 'documentSubType'> {
  const documentTypeInput: DocumentTypeInput = formData?.state?.values?.documentType;

  if (state.source === 'apply-nz' && state.method === 'DOCUMENTS') {
    // Note, technically we are not sure if user is doing POI or POA.
    // But business (@joel) has made the decision to always use POA for Apply NZ when uploading non-payslip documents.
    return {
      documentType: 'PROOF_OF_ADDRESS',
      documentSubType: 'OTHER',
    };
  } else if (documentTypeInput) {
    return {
      documentType: documentTypeInput,
      documentSubType: 'OTHER',
    };
  } else if (state.method === 'PAYSLIPS') {
    return {
      documentType: 'PROOF_OF_INCOME',
      documentSubType: 'PAYSLIP',
    };
  } else if (state.method === 'DOCUMENTS') {
    return {
      documentType: 'PROOF_OF_INCOME',
      documentSubType: 'OTHER',
    };
  } else {
    // Note, we don't expect user come from source Apply AU.
    throw new Error(`Unexpected document type. state.method: ${state.method}, documentTypeInput: ${documentTypeInput}, state.source: ${state.source}`);
  }
}

function businessFields(state: StateNeeded, formData: FormData): Pick<DocUploadProps, 'businessUnit' | 'clientId' | 'channel' | 'function'> {
  const businessUnit = 'LPAY';
  const instoreChannel = state.channel?.toLowerCase() === 'instore' ? 'INSTORE' : null;
  const documentTypeInput: DocumentTypeInput = formData?.state?.values?.documentType;

  switch (state.source) {
    case 'website-au':
      return {
        businessUnit,
        clientId: (state.method === 'DOCUMENTS' && documentTypeInput === 'OTHER') ? 'SERVICING_AU' : 'ORIGINATIONS_AU',
        channel: instoreChannel || 'WEBSITE',
        function: 'OTHER',
      };
    case 'website-nz':
      return {
        businessUnit,
        clientId: (state.method === 'DOCUMENTS' && documentTypeInput === 'OTHER') ? 'SERVICING_NZ' : 'ORIGINATIONS_NZ',
        channel: instoreChannel || 'WEBSITE',
        function: 'OTHER',
      };
    case 'smp-au':
      return {
        businessUnit,
        clientId: 'PARTNER_AU',
        channel: 'INSTORE',
        function: 'NEW_APPLICATION',
      };
    case 'smp-nz':
      return {
        businessUnit,
        clientId: 'PARTNER_NZ',
        channel: 'INSTORE',
        function: 'NEW_APPLICATION',
      };
    case 'cli-au':
      // LSC CLI AU actually has embedded Doc Uploader in the web app.
      // So that we don't expect this source to be used for Income LFS.
      // However, we will support it just in case.
      return {
        businessUnit,
        clientId: 'CREDIT_LIMIT_INCREASE_AU',
        channel: instoreChannel || 'ONLINE',
        function: 'CREDIT_LIMIT_INCREASE',
      };
    case 'cli-nz':
      // LSC CLI NZ actually has embedded Doc Uploader in the web app.
      // So that we don't expect this source to be used for Income LFS.
      // However, we will support it just in case.
      return {
        businessUnit,
        clientId: 'CREDIT_LIMIT_INCREASE_NZ',
        channel: instoreChannel || 'ONLINE',
        function: 'CREDIT_LIMIT_INCREASE',
      };
    case 'apply-au':
      return {
        businessUnit,
        clientId: 'ORIGINATIONS_AU',
        channel: instoreChannel || 'ONLINE',
        function: 'NEW_APPLICATION',
      };
    case 'apply-nz':
      return {
        businessUnit,
        clientId: 'ORIGINATIONS_NZ',
        channel: instoreChannel || 'ONLINE',
        function: 'NEW_APPLICATION',
      };
    default:
      throw new Error(`Unexpected source. state.source: ${state.source}`);
  }
}
