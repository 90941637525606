import React, { useEffect } from 'react';
import { usePrevious } from 'hooks';
import { Input } from 'basis';
import { FormData, FormFieldConfig } from 'components';

type Props = {
  formData: FormData;
  config: FormFieldConfig[];
  disabled?: boolean;
};

export const LoginFields: React.FC<Props> = ({ formData, config, disabled }) => {
  const { state, validateField } = formData;
  const { emailAddress, confirmEmail } = state.values;
  const prevEmailAddress = usePrevious(emailAddress);

  useEffect(() => {
    if (emailAddress !== prevEmailAddress && confirmEmail.trim() !== '') {
      validateField('confirmEmail');
    }
  }, [emailAddress, prevEmailAddress, confirmEmail, validateField]);

  return (
    <>
      {config.map(
        ({ type, ...props }: FormFieldConfig) =>
          type === 'Input' && (
            <Input {...props} key={props.name} validateData={{ values: formData.state.values }} disabled={disabled} />
          ),
      )}
    </>
  );
};
