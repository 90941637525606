import React, { createContext, useReducer, useEffect } from 'react';
import { State } from 'types';
import { reducer } from './reducer';
import { initialState } from './initialState';
import { Action } from './actions';
import { useEnv } from 'hooks';
import { getSessionStorage, setSessionStorage } from '../utils';

export const STATE_SESSION_STORAGE_KEY = 'state';

export type Dispatch = (action: Action) => void;
export type ContextValue = {
  state: State;
  dispatch: Dispatch;
};

export const Context = createContext<ContextValue>({
  state: initialState,
  dispatch: () => null,
});

export interface ContextProps {
  children: React.ReactNode;
  overrideInitState?: Partial<State>;
}

export const ContextProvider: React.FC<ContextProps> = (props) => {
  const getEnv = useEnv();
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    // Load state from sessionStorage to prevent crash on page refresh
    ...getSessionStorage(STATE_SESSION_STORAGE_KEY),
    ...props.overrideInitState,
  });

  useEffect(() => {
    setSessionStorage(STATE_SESSION_STORAGE_KEY, state);
  }, [state, getEnv]);

  return (
    <Context.Provider
      children={children}
      value={{
        state,
        dispatch,
      }}
    />
  );
};
