import { Vars } from './initMessagesObject';

export const replaceStringVars = (string: string, vars: Vars): string => {
  return Object.keys(vars).reduce((acc, key) => {
    if (key) {
      const re = new RegExp(`{{${key}}}`, 'g');
      return acc.replace(re, String(vars[key]));
    }
    return acc;
  }, string);
};
