import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutPage, LayoutForm, Form, FormData } from 'components';
import { byMethod } from 'utils/state';
import { useAppContext } from 'hooks/useAppContext';
import { UploadMethods } from 'doc-upload';
import { submitHandler } from 'utils';
import { Routes } from 'types';
import { useTrackingEvent } from 'hooks';
import { setComplete } from 'context/actions';
import { UploadPayslips } from './UploadPayslips';
import { UploadDocuments } from './UploadDocuments';
import { BackNavigator } from 'components/layout-page/BackNavigator';

const FORM_ID = 'uploadDocuments';

const initialValues = {
  reference: "",
  documentType: ""
};

export const Upload: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = useAppContext();
  const methodsRef = useRef<UploadMethods | undefined>();
  const { trackEvent } = useTrackingEvent();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(({ errors, values }) => {
    submitHandler({
      errors,
      submit: async () => {
        setSubmitting(true);
        const result = await methodsRef.current?.startUpload?.();
        setSubmitting(false);

        if (result?.ok) {
          trackEvent({
            category: 'application',
            action: 'application-submitted',
            location: window.location.pathname.slice(1),
            label: 'submit',
          });

          dispatch(setComplete(true));
          history.push(Routes.outcome);
        }
      },
    });
  }, [dispatch, history, trackEvent]);

  return (
    <LayoutPage step="update">
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        onSubmit={onSubmit}
        loading={submitting}
        submitButtonLabel={submitting ? 'Submitting...' : 'Submit'}
      >
        {(formData: FormData) => {
          return (
            <LayoutForm>
              <BackNavigator />
              {byMethod(state, {
                payslips: <UploadPayslips state={state} formData={formData} methodsRef={methodsRef} />,
                documents: <UploadDocuments state={state} formData={formData} methodsRef={methodsRef} />,
              })}
            </LayoutForm>
          );
        }}
      </Form>
    </LayoutPage>
  );
};
