import { CountryCode, State } from "types";

export function byCountry<T>(state: State, countries: {
  nz: T | (() => T);
  au: T | (() => T);
}) {
  const getValue = (value: T | (() => T)): T =>
    typeof value === 'function' ? (value as () => T)() : value;

  return state.countryCode === CountryCode.NZ ? getValue(countries.nz) : getValue(countries.au);
}

export function byMethod<T>(state: State, methods: {
  payslips: T | (() => T);
  documents: T | (() => T);
}): T {
  const getValue = (value: T | (() => T)): T =>
    typeof value === 'function' ? (value as () => T)() : value;

  const validMethods = ['PAYSLIPS', 'DOCUMENTS'];
  if (!state?.method || !validMethods.includes(state.method)) {
    throw new Error(`Invalid method: ${state?.method}. Expected one of: ${validMethods.join(', ')}`);
  }

  return state.method === 'PAYSLIPS' ? getValue(methods.payslips) : getValue(methods.documents);
}

export function bySource<T>(state: State, sources: {
  websiteAu: T | (() => T);
  websiteNz: T | (() => T);
  cliAu: T | (() => T);
  cliNz: T | (() => T);
  smpAu: T | (() => T);
  smpNz: T | (() => T);
}): T {
  const getValue = (value: T | (() => T)): T =>
    typeof value === 'function' ? (value as () => T)() : value;

  if (!state.method || state.method === 'FI_CONNECT') {
    throw new Error(`Unexpected method: ${state.method}`);
  }

  return state.source === 'website-au' ? getValue(sources.websiteAu) : getValue(sources.websiteNz);
}

export function isWebsiteSourced(state: State) {
  return ['website-au', 'website-nz'].includes(state.source);
}

export function isPoaSupported(state: State) {
  return isWebsiteSourced(state) || state.source === 'apply-nz';
}
