import { oktaAuthClient } from './oktaAuthClient';
import jwtDecode from 'jwt-decode';
import { AuthTransaction } from '@okta/okta-auth-js/lib/tx';
import { FactorType } from 'types';

export const getSubFromToken = (jwt?: string): string => {
  try {
    // @ts-ignore
    return jwt ? jwtDecode(jwt).sub : '';
  } catch (e) {
    return '';
  }
};

export const getAuthHeader = (token?: string): string | undefined => {
  const accessToken = token || oktaAuthClient.getAccessToken();
  return accessToken ? `Bearer ${accessToken}` : undefined;
};

export const getLatitudeId = (token?: string): string | undefined => {
  const accessToken = token || oktaAuthClient.getAccessToken();
  return getSubFromToken(accessToken);
};

type FactorFound = { verify: (props?: { passCode: string }) => Promise<AuthTransaction> };

export const findOTPFactor = (authTxn: AuthTransaction, factorType: FactorType): FactorFound => {
  return authTxn.factors?.find(factor => factor.provider === 'OKTA' && factor.factorType === factorType) as FactorFound;
};
