import React from 'react';
import { Button } from 'basis';
import { LayoutSection } from 'components';
import styled from 'theme/styled';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  button {
    max-width: 100%;

    @media (min-width: 768px) {
      max-width: 250px;
    }
  }
`;

type Props = {
  loading?: boolean;
  submitButtonLabel?: string;
};

export const FormButtons: React.FC<Props> = ({ loading, submitButtonLabel }) => {
  return (
    <LayoutSection>
      <ButtonContainer>
        <Button type="submit" width="100%" loading={loading} testId="submit-button">
          {submitButtonLabel}
        </Button>
      </ButtonContainer>
    </LayoutSection>
  );
};
