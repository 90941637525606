import React from 'react';
import { Text, Container, Button } from 'basis';
import styled from '@emotion/styled';
import { useTheme } from 'theme';
import { LayoutSection, PhoneNumberLink } from 'components';
import { Routes } from 'types';
import ErrorDogImage from 'images/errordog.svg';
import { useHistory } from 'react-router-dom';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 100%;
`;

const ContentWrapper = styled.div`
  max-width: 450px;
`;

type Props = {
  onRetry?: () => Promise<any>;
  retrying?: boolean;
  boundary?: boolean;
  fiConnect?: boolean;
};

export const Error: React.FC<Props> = ({ fiConnect, boundary, onRetry, retrying }) => {
  const theme = useTheme();
  const history = useHistory();

  const handleBackToMainClick = () => {
    history.push(Routes.main);
  };

  return (
    <LayoutSection>
      <ErrorWrapper data-testid={boundary ? 'error-boundary' : 'error'}>
        <Container>
          <img src={ErrorDogImage} alt={ErrorDogImage} />
        </Container>

        <ContentWrapper>
          <Container padding="6 0 0 0">
            <Text as="h4" textStyle="heading4" color={theme.headingColor} align="left">
              {fiConnect ? 'Oops, there was a problem connecting to your bank' : 'Oops, Something went wrong'}
            </Text>
          </Container>

          <Container padding="4 0">
            <Text as="p">
              {fiConnect
                ? 'To verify your income you can either upload documents or you can try sharing your bank statements again'
                : 'Looks like the server is taking too long to respond.'}
            </Text>
          </Container>

          {fiConnect && (
            <>
              <Container padding="2 0 0 0">
                <Button onClick={handleBackToMainClick} width="100%" testId="back-to-main-button">
                  Back to main menu
                </Button>
              </Container>
            </>
          )}

          {onRetry && (
            <Container padding="2 0 0 0">
              <Button loading={retrying} onClick={onRetry} testId="retry-button">
                Retry
              </Button>
            </Container>
          )}

          <Container padding="12 0 0 0">
            <Text as="h5" textStyle="heading5" color={theme.headingColor} align="left">
              Need help? Call us
            </Text>
          </Container>

          <Container padding="2 0 0 0">
            <PhoneNumberLink
              supportNumberType="general"
              appearance="secondary-button"
              variant="blue-button"
              width="100%"
              prefix="Call"
            />
          </Container>

          <Container padding="6 0 0 0">
            <Text>Monday - Wednesday: 8.30am - 6pm</Text>
            <Text>Thursday: 8.30am - 9pm</Text>
            <Text>Friday: 8.30am - 7pm</Text>
            <Text>Saturday & Sunday: 9am - 6pm</Text>
          </Container>
        </ContentWrapper>
      </ErrorWrapper>
    </LayoutSection>
  );
};
