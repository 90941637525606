import { useContext } from 'react';
import { Actions, Context, ContextValue } from 'context';
import { PartialDeep, State } from 'types';

export function useAppContext() {
  const ctx: ContextValue = useContext(Context);
  return {
    ...ctx,
    updateState: (statePatch: PartialDeep<State>) => {
      ctx.dispatch({
        type: Actions.PATCH,
        data: statePatch,
      });
    },
  };
}

type Selector<T> = (ctx: ContextValue) => T;

export function useAppContextSelector<T = any>(selector: Selector<T>): T {
  const ctx: ContextValue = useContext(Context);
  return selector(ctx);
}
