type ThemeNeeded = {
  space: Record<number, string>;
  radii: Record<number, string>;
  colors: {
    black: string;
    white: string;
    secondary: {
      lightBlue: {
        t100: string;
      };
    };
  };
  fontWeights: {
    medium: number | string;
  };
};

type TextStyle = 'subtitle1' | 'subtitle2' | string;
type ListType = 'unordered' | 'ordered' | 'steps';
type TargetElement = 'list' | 'item';

interface GetTextStyleParams {
  name: TextStyle;
  mode: 'container';
}

interface GetCSSParams {
  targetElement: TargetElement;
  type: ListType;
  variant?: string;
  textStyle: TextStyle;
}

export function listTheme(
  theme: ThemeNeeded,
  { getTextStyle }: { getTextStyle: (params: GetTextStyleParams) => Record<string, unknown> }
) {
  return {
    getCSS: ({ targetElement, type, variant, textStyle }: GetCSSParams) => {
      switch (targetElement) {
        case 'list': {
          return {
            margin: 0,
            listStyleType: 'none',
            ...(type === 'unordered' && {
              paddingLeft: '1.25em',
            }),
            ...(type === 'ordered' && {
              counterReset: 'ordered',
              paddingLeft: '1.25em',
            }),
            ...(type === 'steps' && {
              counterReset: 'steps',
              padding: '0.25em 0 0.25em 2.5em',
            }),
            ...getTextStyle({ name: textStyle, mode: 'container' }),
          };
        }

        case 'item': {
          switch (type) {
            case 'unordered': {
              return {
                position: 'relative',
                ':not(:first-of-type)': {
                  marginTop:
                    textStyle === 'subtitle1'
                      ? theme.space[4]
                      : textStyle === 'subtitle2'
                      ? theme.space[3]
                      : theme.space[2],
                },
                '::before': {
                  content: '""',
                  width: '0.28em',
                  height: '0.28em',
                  borderRadius: theme.radii[3],
                  position: 'absolute',
                  top: '0.6em',
                  left: '-1.25em',
                  backgroundColor: '#414141',
                },
                '& ul, & ol': {
                  marginTop: '0.5em',
                },
                '&:not(:last-of-type) ul, &:not(:last-of-type) ol': {
                  marginBottom: '1em',
                },
              };
            }

            case 'ordered': {
              return {
                position: 'relative',
                counterIncrement: 'ordered',
                ':not(:first-of-type)': {
                  marginTop:
                    textStyle === 'subtitle1'
                      ? theme.space[4]
                      : textStyle === 'subtitle2'
                      ? theme.space[3]
                      : theme.space[2],
                },
                '::before': {
                  content: 'counter(ordered, decimal) ". "',
                  position: 'absolute',
                  top: 0,
                  left: '-1.25em',
                },
                '& ul, & ol': {
                  marginTop: '0.5em',
                },
                '&:not(:last-of-type) ul, &:not(:last-of-type) ol': {
                  marginBottom: '1em',
                },
                '& ol li::before': {
                  content: 'counter(ordered, lower-alpha) ". "',
                },
                '& ol ol li::before': {
                  content: 'counter(ordered, lower-roman) ". "',
                },
              };
            }

            case 'steps': {
              return {
                position: 'relative',
                counterIncrement: 'steps',
                marginBottom: '16px',
                ':last-of-type': {
                  marginBottom: 0,
                },
                '::before': {
                  content: 'counter(steps, decimal)',
                  width: '1.5em',
                  height: '1.5em',
                  lineHeight: '1.5em',
                  color: theme.colors.white,
                  backgroundColor: 'black',
                  fontWeight: theme.fontWeights.medium,
                  textAlign: 'center',
                  borderRadius: theme.radii[3],
                  position: 'absolute',
                  top: '-0.05em',
                  left: '-2.5em',
                },
                '& ul, & ol': {
                  marginTop: '1em',
                },
                '&:not(:last-of-type) ul, &:not(:last-of-type) ol': {
                  marginBottom: '1.25em',
                },
                '& ol li::before': {
                  content: 'counter(steps, lower-alpha)',
                  backgroundColor: theme.colors.secondary.lightBlue.t100,
                },
              };
            }

            default: {
              return null;
            }
          }
        }

        default: {
          return null;
        }
      }
    },
  };
}
