import React from 'react';
import { Sticky, Container } from 'basis';
import { Header, Footer, ProgressStepper } from 'components';
import { Step } from 'types';

type Props = {
  children: React.ReactNode;
  step: Step;
  hideProgressStepper?: boolean;
  paddingBottom?: number;
};

export const LayoutPage: React.FC<Props> = ({ children, hideProgressStepper, step, paddingBottom }) => {
  return (
    <>
      <Sticky>
        <Sticky.Item>
          <Header />
        </Sticky.Item>
        {!hideProgressStepper && (
          <Sticky.Item>
            <ProgressStepper step={step} />
          </Sticky.Item>
        )}
      </Sticky>
      <main>
        <Container testId="LayoutPage.Main.ContentContainer" width="100%" padding={`0 0 ${paddingBottom ?? 12}`}>
          {children}
        </Container>
      </main>
      <Footer />
    </>
  );
};
