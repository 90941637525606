import { useCallback } from 'react';
import { Routes } from 'types';
import styled from '@emotion/styled';
import { setSessionStorage } from 'utils';
import { STATE_SESSION_STORAGE_KEY } from 'context/context';

export function DevTeleport({ setAllowNavigationToAllRoutes }: { setAllowNavigationToAllRoutes: () => void }) {
  const teleport = useCallback(async (path: string) => {
    setAllowNavigationToAllRoutes();
    setSessionStorage(STATE_SESSION_STORAGE_KEY, {});
    window.location.href = path;
  }, [setAllowNavigationToAllRoutes]);

  return (
    <Wrapper>
      {buttons.map(({ label, path }) => (
        <button key={label} onClick={() => teleport(path)}>{label}</button>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #d6ebff;

  button {
    padding: 0.5rem 1rem;
    background-color: #d6ebff;
    border: none;
    cursor: pointer;
    font-weight: 600;
  }
`;

const buttons = [
  {
    label: 'Outcome',
    path: Routes.outcome,
  },
  {
    label: 'Main page for SMP AU & show Bank Connect',
    path: '/main?source=smp-au&appNum=1234567890123&assessmentId=mockAssessmentId',
  },
  {
    label: 'Main page for SMP AU & hide Bank Connect',
    path: '/main?source=smp-au&appNum=1234567890123',
  },
  {
    label: 'Main page for SMP NZ & show Bank Connect',
    path: '/main?source=smp-nz&appNum=1234567890123&assessmentId=mockAssessmentId',
  },
  {
    label: 'Main page for SMP NZ & hide Bank Connect',
    path: '/main?source=smp-nz&appNum=1234567890123',
  },
  {
    label: 'Main page for CLI AU & show Bank Connect',
    path: '/main?source=cli-au&appNum=1234567890123&assessmentId=mockAssessmentId',
  },
  {
    label: 'Main page for CLI AU & hide Bank Connect',
    path: '/main?source=cli-au&appNum=1234567890123',
  },
  {
    label: 'Main page for Apply NZ & show Bank Connect & in-store channel',
    path: '/main?source=apply-nz&appNum=1234567890123&channel=instore&assessmentId=mockAssessmentId',
  },
  {
    label: 'Main page for Apply NZ & hide Bank Connect & default channel',
    path: '/main?source=apply-nz&appNum=1234567890123',
  },
  {
    label: 'Main page for Website AU',
    path: '/main?source=website-au',
  },
  {
    label: 'Main page for Website NZ',
    path: '/main?source=website-nz',
  },
]
