import styled from "@emotion/styled";

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    padding-right: 16px;
  }
`;
