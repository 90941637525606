import { useMemo } from 'react';
import { Text, Link } from 'basis';
import { byCountry, isWebsiteSourced } from 'utils';
import { State } from 'types';
import { FormData } from 'components';
import { IconWrapper } from './styles';
import { DocUpload, UploadMethods } from 'doc-upload';
import { toDocUploadProps } from './toDocUploadProps';
import { SUPPORTED_DOC_LIST_URLS } from './utils';
import { DocumentTypeField, ReferenceField } from './Fields';
import { MoneyDocumentsIcon } from 'components/icons';
import { Title } from 'components/page-title/PageTitle';

export function UploadDocuments({ state, formData, methodsRef }: { state: State, formData: FormData, methodsRef: React.MutableRefObject<UploadMethods | undefined> }) {
  const [firstContent, ...restContent] = useMemo(() => getContent(state), [state]);

  return (
    <>
      <Title>Upload your documents</Title>
      <IconWrapper>
        <MoneyDocumentsIcon width={49} height={61} />
        {firstContent}
      </IconWrapper>
      {restContent}
      <ReferenceField />
      <DocumentTypeField formData={formData} />
      <DocUpload {...toDocUploadProps({ state, formData, methodsRef })} />
    </>
  );
}

function getContent(state: State) {
  const linkToSupportedDocList = byCountry(state, SUPPORTED_DOC_LIST_URLS);

  if (isWebsiteSourced(state)) {
    return [
      <Text>
        View our <Link href={linkToSupportedDocList} newTab>list of acceptable proof of income documents</Link>.
      </Text>
    ];
  }

  return [
    <Text>
      Bank statements and other documents will take 1-48 hours to review.
    </Text>,
    <Text>
      View our <Link href={linkToSupportedDocList} newTab>list of acceptable proof of income documents</Link>.
    </Text>,
  ]
}
