import { useEventTracking } from 'react-event-tracker';
import { getTrackingEventData } from 'utils';
import { State } from 'types';
import { useCallback } from 'react';
import { useAppContextSelector } from './useAppContext';

type TrackEventArgs = {
  category: string;
  action: string;
  label: string;
  location: string;
  data?: Partial<State>;
};

type UseTrackingEvent = {
  trackEvent: ({ category, action, label, location, data }: TrackEventArgs) => void;
  getQueryString: ({ category, action, label, location }: TrackEventArgs) => string;
};

export const useTrackingEvent = (): UseTrackingEvent => {
  const state = useAppContextSelector((ctx) => ctx.state);
  const { trackEvent, getQueryString } = useEventTracking();

  const track = useCallback(
    ({ category, action, label, location, data }: TrackEventArgs) => {
      const payload = {
        event: {
          category,
          action,
          location,
          label,
        },
        ...getTrackingEventData({ ...state, ...data }),
      };
      trackEvent(payload);
    },
    [state, trackEvent],
  );

  const query = useCallback(
    ({ category, action, label, location }: TrackEventArgs) => {
      const payload = {
        event: {
          category,
          action,
          location,
          label,
        },
      };
      return getQueryString(payload);
    },
    [getQueryString],
  );

  return {
    trackEvent: track,
    getQueryString: query,
  };
};
