import React from 'react';
import { Form as BasisForm } from 'basis';
import { DevAutoFill } from '__dev/DevAutoFill';
import { FormButtons } from './FormButtons';
import { FormValidateFnc } from 'utils';
import { LayoutSection } from '../layout-section/LayoutSection';

export type FormValues = {
  [key: string]: any;
};

export type FormErrors = {
  [key: string]: string | string[];
};

export type FormState = {
  values: FormValues;
  errors: FormErrors;
  setErrors: (errors: FormErrors) => void;
};

export type FormData = {
  resetForm: (arg: { values?: FormValues; errors?: FormErrors }) => void;
  setErrors: (arg: FormErrors) => void;
  setValues: (arg: (values: FormValues) => FormValues) => void;
  state: FormState;
  submitForm: (props: FormState) => void;
  validateField: (name: string) => void;
};

export type FormFieldConfig = {
  type?: string;
  name: string;
  label?: string;
  testId?: string;
  helpText?: string;
  maxLength?: number;
  pasteAllowed?: boolean;
  validate?: FormValidateFnc | false;
  fields?: FormFieldConfig[];
};

export type FormValidateOptions = {
  isEmpty: (value: string) => boolean;
  data: { values?: FormValues; codeLength?: number };
};

export interface Props {
  id?: string;
  initialValues: FormValues;
  onSubmit: (props: FormState) => void;
  children: React.ReactNode;
  loading: boolean;
  debug?: boolean;
  hideFormButtons?: boolean;
  submitButtonLabel: string;
}

const Form: React.FC<Props> = (props) => {
  const { id, initialValues, onSubmit, children, debug, loading, hideFormButtons, submitButtonLabel } = props;
  return (
    <BasisForm initialValues={initialValues} onSubmit={onSubmit} debug={debug}>
      {(formData: FormData) => (
        <>
          <LayoutSection paddingTop={0} position="relative">
            <DevAutoFill id={id} setFormValues={formData.setValues} />
          </LayoutSection>
          {typeof children === 'function' ? children(formData) : children}
          {!hideFormButtons && <FormButtons loading={loading} submitButtonLabel={submitButtonLabel} />}
        </>
      )}
    </BasisForm>
  );
};

export { Form };
