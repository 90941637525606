import React from 'react';
import { Stepper } from 'basis';
import { Step } from 'types';
import styled from 'theme/styled';

const Numberless = styled.div`
  div > div > div > div > div:nth-child(2) p {
    display: none;
  }
`;

type Props = {
  step: Step;
};

export const ProgressStepper: React.FC<Props> = ({ step }) => {
  return (
    <Numberless data-testid="numberless">
      <Stepper>
        <Stepper.Item label="Login" label-md="Login" current={step === 'login'} />
        <Stepper.Item label="Upload" label-sm="Upload" current={step === 'update'} />
        <Stepper.Item label="Outcome" label-sm="Outcome" current={step === 'outcome'} />
      </Stepper>
    </Numberless>
  );
};

// @ts-ignore
ProgressStepper.ID = 'ProgressStepper';
// @ts-ignore
ProgressStepper.HEIGHT_MAP = {
  default: Stepper.HEIGHT_MAP.default,
};
