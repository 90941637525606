import { Routes } from '../types';

export const validEmailPattern =
  '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
export const emailMaxLength = 255;

export const ROUTE_CONFIG = {
  [Routes.init]: {
    siteSubSection: 'poi-process',
    pageTitle: 'Login to your account',
    tealiumPageTitle: 'contact-details',
  },
  [Routes.verify]: { siteSubSection: 'poi-process', pageTitle: 'Verify', tealiumPageTitle: 'verify' },
  [Routes.main]: {
    siteSubSection: 'poi-process',
    tealiumPageTitle: 'poi-landing',
    pageTitle: 'Verify your documents',
  },
  [Routes.connect]: { siteSubSection: 'poi-process', pageTitle: 'fi-connect', tealiumPageTitle: 'bank-connect' },
  [Routes.upload]: { siteSubSection: 'poi-process', pageTitle: 'documents', tealiumPageTitle: 'documents' },
  [Routes.outcome]: {
    siteSubSection: 'poi-process',
    pageTitle: 'We have received your documents!',
    tealiumPageTitle: 'referred',
  },
  [Routes.acceptance]: { siteSubSection: 'pca-process', pageTitle: 'Offer Acceptance', tealiumPageTitle: 'acceptance' },
  [Routes.acceptanceConfirmation]: { siteSubSection: 'pca-process', pageTitle: 'Thank you for accepting the offer', tealiumPageTitle: 'acceptance-confirmation' },
};

export const config = {
  validEmailPattern,
  emailMaxLength,
  routeConfig: ROUTE_CONFIG,
};

export * from './tealiumConfig';
export * from './features';
