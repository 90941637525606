import { AuthTransaction } from '@okta/okta-auth-js/lib/tx';

export enum CountryCode {
  NZ = 'NZ',
  AU = 'AU',
}

export type SupportNumberType = 'general' | 'generalAlt';

export enum Routes {
  init = '/',
  verify = '/verify',
  acceptance = '/acceptance',
  acceptanceConfirmation = '/acceptance-confirmation',
  main = '/main',
  connect = '/connect',
  upload = '/upload',
  outcome = '/outcome',
}

export enum ApiType {
  apollo = 'apollo',
  axios = 'axios',
  okta = 'okta',
}

export enum ErrorType {
  UNKNOWN_API_ERROR = 'UNKNOWN_API_ERROR',
  APOLLO_NETWORK_ERROR = 'APOLLO_NETWORK_ERROR',
  APOLLO_GRAPHQL_ERROR = 'APOLLO_GRAPHQL_ERROR',
  APOLLO_UNKNOWN_ERROR = 'APOLLO_UNKNOWN_ERROR',
  AXIOS_NETWORK_ERROR = 'AXIOS_NETWORK_ERROR',
  AXIOS_NO_RESPONSE_ERROR = 'AXIOS_NO_RESPONSE_ERROR',
  AXIOS_UNKNOWN_ERROR = 'AXIOS_UNKNOWN_ERROR',
  OKTA_ERROR = 'OKTA_ERROR',
  OKTA_LOGIN_AND_SEND_OTP_ERROR = 'OKTA_LOGIN_AND_SEND_OTP_ERROR',
  OKTA_SYNTHETIC_TOKEN_ERROR = 'OKTA_SYNTHETIC_TOKEN_ERROR',
  OKTA_VERIFICATION_CODE_INCORRECT_ERROR = 'OKTA_VERIFICATION_CODE_INCORRECT_ERROR',
  OKTA_VERIFICATION_LOCKED_ERROR = 'OKTA_VERIFICATION_LOCKED_ERROR',
  OKTA_VERIFICATION_ERROR = 'OKTA_VERIFICATION_ERROR',
  FI_CONNECT_ERROR = 'FI_CONNECT_ERROR',
}

export const OKTA_ERROR_CODE_INVALID_PASSCODE_FOR_INCORRECT_OTP = 'E0000068';
export const OKTA_ERROR_CODE_INVALID_TOKEN_FOR_EXPIRED_OTP = 'E0000011';

export type State = {
  countryCode: CountryCode;
  source: 'website-au' | 'website-nz' | 'smp-au' | 'smp-nz' | 'cli-au' | 'cli-nz' | 'apply-au' | 'apply-nz';
  channel?: 'instore' | string;
  empStatus?: string;
  appNum?: string;
  cardType?: string;
  assessmentId?: string;
  correlationId?: string;
  accessToken: string;
  latitudeId: string;
  error: ApiError | null;
  contactDetails: ContactDetails;
  verification: VerificationDetails;
  fiConnect: FiConnectDetails;
  method: Method | null;
  tealium: TealiumDetails;
  complete: boolean;
};

export type FactorType = 'sms' | 'email';

export type Step = 'login' | 'update' | 'outcome';

export type ContactDetails = {
  mobileNumber: string;
  emailAddress: string;
  confirmEmail: string;
};

export type AuthDetails = {
  accessToken: string;
  latitudeId: string;
  mobileNumber: string;
};

export type VerificationDetails = {
  verificationLocked: boolean;
  maskedMobileNumber: string;
  oktaAuthTxn: AuthTransaction | null;
  oktaProfileHealthy: boolean;
  attemptingFactorType: FactorType | null;
};

export type Method = 'FI_CONNECT' | 'DOCUMENTS' | 'PAYSLIPS';

export type FiConnectDetails = {
  userToken: string;
  guid: string;
};

export type TealiumDetails = {
  poiStartDate: string;
  bankSelected: string;
  numberOfAccountsSelected: string;
  numberOfTransactionsSelected: string;
};

export type ApiError = {
  type: ErrorType;
  error: {
    message: string;
    code: string;
  };
};

export type DataLayer = {
  application: {
    applicationChannel: string;
    applicationOutcome: string;
    applicationOutcomeReason: string;

    poi: {
      assessmentId?: string;
      poiStartDate: string;
      bankSelected: string;
      numberOfAccountsSelected: string | number;
      numberOfTransactionsSelected: string | number;
    };
  };
  applicant: {
    personal: {
      emailAddress: string;
      mobileNumber: string;
    };
  };
};

export type PartialDeep<T> = T extends object ? {
  [P in keyof T]?: PartialDeep<T[P]>;
} : T;

declare global {
  interface Window {
    utag_cfg_ovrd: {
      noview: boolean;
    };
    Cypress: any;
    getAccessToken: () => string | undefined;
  }
}
