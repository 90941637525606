import { useState } from 'react';
import axios from 'axios';
import { useHandleApiErrors } from './useHandleApiErrors';
import { ApiError, ApiType } from 'types';
import { useAppContextSelector } from './useAppContext';
import { getAuthHeader } from 'utils';

export type InitFiConnectData = {
  userToken: string;
  guid: string;
};

type UseInitFiConnectResponse = InitFiConnectData | ApiError;

type UseInitFiConnect = {
  loading: boolean;
  initFiConnect: () => Promise<UseInitFiConnectResponse>;
};

type UseInitFiConnectArgs = {
  onSuccess?: (data: any) => void;
  onError?: (error: ApiError) => void;
};

export const useInitFiConnect = ({ onSuccess, onError }: UseInitFiConnectArgs = {}): UseInitFiConnect => {
const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiErrors(ApiType.axios, onError);
  const { accessToken, assessmentId } = useAppContextSelector((ctx) => ctx.state);

  const initFiConnect = async (): Promise<UseInitFiConnectResponse> => {
    const url = process.env.REACT_APP_INIT_API_URL as string;
    const body = {};
    const options = {
      headers: {
        Authorization: getAuthHeader(accessToken),
        Accept: 'application/json',
      },
    };

    try {
      setLoading(true);

      const result = await axios.post(`${url}/assessments/${assessmentId}/bankconnect/sessions`, body, options);

      setLoading(false);

      const data = {
        // @ts-ignore
        userToken: result.data.userToken,
        // @ts-ignore
        guid: result.data.guid,
      };
      onSuccess && onSuccess(data);
      return data;
    } catch (error) {
      setLoading(false);
      return handleError(error);
    }
  };

  return { loading, initFiConnect };
};
