import React from 'react';
import { Footer, Link, Text } from 'basis';
import { FooterLinkKey, FooterLinks } from './NzFooter';
import { byCountry } from 'utils';
import { useAppContext } from 'hooks';

type Props = {
  trackEventSender: (label: string) => void;
  getLinkWithAnalytics: (key: FooterLinkKey) => string;
  footerLinks: FooterLinks;
};

export const DefaultFooter: React.FC<Props> = ({ trackEventSender, getLinkWithAnalytics, footerLinks }) => {
  const { state } = useAppContext();
  return (
    <Footer>
      <Footer.Header testId="footer">
        <Footer.Header.Logo name={byCountry(state, { nz: 'gem', au: 'latitude' })} />
      </Footer.Header>
      <Footer.Legal testId="footer">
        <Footer.Legal.Links>
          {Object.keys(footerLinks).map((k) => {
            const key = k as FooterLinkKey;
            return (
              <Link
                key={key}
                href={getLinkWithAnalytics(key)}
                newTab
                onClick={() => trackEventSender(footerLinks[key].label)}
              >
                {footerLinks[key].label}
              </Link>
            );
          })}
        </Footer.Legal.Links>
        <Footer.Legal.Copy>
          <Text>
            {byCountry(state, {
              nz: 'Gem Visa is provided by Latitude Financial Services Limited.',
              au: 'Credit facility provided by Latitude Finance Australia ABN 42 008 583 588. Australian Credit Licence Number 392145. 130 Lonsdale Street, Melbourne 3000.',
            })}
          </Text>
        </Footer.Legal.Copy>
      </Footer.Legal>
    </Footer>
  );
};
