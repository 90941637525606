import React from 'react';
import { Link } from 'basis';
import { State, SupportNumberType } from 'types';
import { byCountry } from 'utils';
import { useAppContext } from 'hooks';

type Props = {
  supportNumberType: SupportNumberType;
  onClick?: () => void;
  appearance?: string;
  variant?: string;
  width?: string;
  prefix?: string;
};

type GetSupportNumberProps = {
  supportNumberType: SupportNumberType;
  state: State;
};

const getSupportNumber = ({ supportNumberType, state }: GetSupportNumberProps) => {
  switch (supportNumberType) {
    case 'general':
      return byCountry(state, { nz: '0800 500 505', au: '0800 500 505' });
    case 'generalAlt':
      return byCountry(state, { nz: '+64 9 362 5432', au: '+61 9 555 4432' });
  }
};

export const PhoneNumberLink: React.FC<Props> = ({ supportNumberType, onClick, appearance, variant, width, prefix }) => {
  const { state } = useAppContext();

  const displayNumber = getSupportNumber({supportNumberType, state});
  const hrefNumber = displayNumber.replace(/\s/g, '');

  return (
    <Link
      href={`tel:${hrefNumber}`}
      newTab={false}
      onClick={onClick}
      appearance={appearance}
      variant={variant}
      {...(width ? { width } : null)}
    >
      {prefix ? `${prefix} ${displayNumber}` : displayNumber}
    </Link>
  );
};
