import { FormErrors } from 'components';

type SubmitHandler = {
  submit: () => void;
  errors?: FormErrors;
};

export function submitHandler({ submit, errors = {} }: SubmitHandler): void {
  if (Object.keys(errors).length === 0) {
    submit();
    return;
  }

  const ariaInvalid = document.querySelectorAll('[aria-invalid=true]');

  if (ariaInvalid.length > 0) {
    window.scrollTo({
      top: ariaInvalid.item(0).getBoundingClientRect().top - document.body.getBoundingClientRect().top,
      behavior: 'smooth',
    });
    return;
  }

  return;
}
