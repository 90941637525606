import { useEffect } from 'react';
import { useAsyncScript } from './useAsyncScript';

export function useTealium() {
  const status = useAsyncScript(
    `//tags.tiqcdn.com/utag/latitudefs/poi-funnel/${process.env.REACT_APP_TEALIUM_ENV}/utag.js`,
  );

  useEffect(() => {
    if (status === 'ready') {
      document.dispatchEvent(new Event('utag-loaded'));
    }
  }, [status]);

  useEffect(() => {
    // See: https://docs.tealium.com/platforms/javascript/single-page-applications/#setup
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true;
  }, []);
}
