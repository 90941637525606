import React from 'react';
import styled from '@emotion/styled';
import { Text, LoadingIcon } from 'basis';

type StyleProps = {
  bgColor?: string;
  bgOpacity?: number;
};

export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
`;

export const Bg = styled.div<StyleProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.bgColor};
  opacity: ${(p) => p.bgOpacity ?? '1'};
`;

export const Content = styled.div`
  position: relative;
  & > *:last-child {
    margin-top: 24px;
  }
`;

export interface Props {
  text?: React.ReactNode;
  bgColor?: string;
  bgOpacity?: number;
}

const Loading: React.FC<Props> = (props) => {
  const { text, bgColor, bgOpacity } = props;
  return (
    <LoadingContainer data-testid="loading">
      {bgColor && <Bg data-testid="loading-bg" bgColor={bgColor} bgOpacity={bgOpacity} />}
      <Content>
        <LoadingIcon size="medium" testId="loading-icon" />
        {text && <Text textStyle="heading6">{text}</Text>}
      </Content>
    </LoadingContainer>
  );
};

export { Loading };
