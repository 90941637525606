import React, { useEffect, useRef, useCallback } from 'react';

type Ref = React.MutableRefObject<HTMLElement | undefined> & React.LegacyRef<HTMLDivElement>;

export const useOnClickOutside = (close: () => void): Ref => {
  const ref = useRef<HTMLElement>();

  const handleClose = useCallback(
    (evt) => {
      if (ref.current && !ref.current.contains(evt.target)) {
        close && close();
      }
    },
    [close],
  );

  useEffect(() => {
    window.addEventListener('click', handleClose);
    return () => {
      window.removeEventListener('click', handleClose);
    };
  }, [handleClose]);

  return ref as Ref;
};
