import { DEV_FEATURES } from '__dev';
import { CountryCode } from 'types';

const prefix = {
  NZ: '+64',
  AU: '+61',
};

const prefixLength = {
  NZ: 2,
  AU: 2,
};

export const toDomesticMobileNumber = (number: string, countryCode: CountryCode): string => {
  const pre = prefixLength[countryCode];
  const regEx = new RegExp(`\\+\\d{${pre}}`);
  if (number.startsWith('+')) {
    if (DEV_FEATURES.OTP_MOBILE_NUMBER) {
      return number.replace(regEx, '0');
    }
    return number.replace(regEx, '0');
  }
  return number;
};

export const toInternationalMobileNumber = (number: string, countryCode: CountryCode): string => {
  return `${prefix[countryCode]}${number.slice(1)}`;
};

export const formatMaskedMobileNumber = (number: string, countryCode: CountryCode) => {
  return toDomesticMobileNumber(number, countryCode).replace('****', ' **** ');
};
