import React from 'react';
import { Helmet } from 'react-helmet-async';

// TODO: What are these values?
export const defaultValues = {
  title: 'Income Latitude Financial',
  description: 'Update this',
  siteUrl: '',
  keywords: '',
};

const getRobotsValue = (noIndex: boolean, noFollow: boolean): string => {
  const output = [];
  noIndex && output.push('noindex');
  noFollow && output.push('nofollow');
  return output.join(', ');
};

const getCanonicalUrl = (url?: string): string | null => {
  if (!url) {
    return null;
  }
  const exp = /https?:\/\//;
  const isRelative = !exp.test(url);
  return isRelative ? `${defaultValues.siteUrl}${url}` : url;
};

type Props = {
  url?: string;
  description?: string;
  keywords?: string;
  title?: string;
  noIndex?: boolean;
  noFollow?: boolean;
  children?: React.ReactNode;
};

export const Metadata: React.FC<Props> = ({
  url,
  description = defaultValues.description,
  keywords = defaultValues.keywords,
  title = defaultValues.title,
  noIndex = false,
  noFollow = false,
  children,
}) => {
  const robots = getRobotsValue(noIndex, noFollow);
  const canonicalUrl = getCanonicalUrl(url);

  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {robots && <meta name="robots" content={robots} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {title && <meta property="og:site_name" content={title} />}
      {title && <meta property="og:title" content={title} />}
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      <meta property="og:description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {children}
    </Helmet>
  );
};
