import React from 'react';
import {Text, Stack, Button} from 'basis';
import {LayoutPage, FormState, LayoutForm, Error} from 'components';
import { ApiError, Routes} from 'types';
import { useTheme } from 'theme';
import { config } from '_config';
import {submitHandler} from "../../utils";
import {useHistory} from "react-router-dom";
import {useAcceptanceOffer} from "../../hooks/useAcceptanceOffer";
import {useAppContext, useTrackingError, useTrackingEvent} from "../../hooks";
import {setError} from "../../context";

export const Acceptance: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { state, dispatch } = useAppContext();

  const { trackEvent } = useTrackingEvent();
  const { trackError } = useTrackingError();

  const handleSubmitSuccess = () => {
    history.push(Routes.acceptanceConfirmation);
  };

  const handleSubmitError = (error: ApiError) => {
    dispatch(setError(error));
    trackError({ error });
  };

  const { loading, acceptanceOffer } = useAcceptanceOffer({ onSuccess: handleSubmitSuccess, onError: handleSubmitError });
  const onSubmit = ({ values, errors, setErrors }: FormState): void => {

    async function acceptanceOfferHandler(){
     await acceptanceOffer();
    }

    trackEvent({
      category: 'application',
      action: 'application-navigation',
      location: 'acceptance',
      label: 'acceptance',
    });

    submitHandler({ submit: acceptanceOfferHandler, errors})

  };


  if (state.error) {
    return (
        <LayoutPage step="login">
          <Error retrying={loading} />
        </LayoutPage>
    );
  }

  return (
    <LayoutPage step="update" >
      <LayoutForm>
        <Stack gap="8">
          <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
            {config.routeConfig[Routes.acceptance].pageTitle}
          </Text>
          <Text>
            I acknowledge receipt of the Initial Disclosure Statement and Conditions of Use, and confirm my acceptance of the offer.
          </Text>
          <Button type="button" onClick={onSubmit} loading={loading}>
            Accept Offer
          </Button>
        </Stack>
      </LayoutForm>
    </LayoutPage>
  );
};
