import React from 'react';
import { Container, OtpInput, Stack, Text } from 'basis';
import { FormValidateOptions } from 'components';
import { FactorType } from 'types';
import styled from 'theme/styled';

const NUMERIC_REGEX = /^\d*$/;

function validate(value: string, { isEmpty, data }: FormValidateOptions) {
  if (isEmpty(value)) {
    return `Please enter your ${data.codeLength} code`;
  }
  const trimmedValue = value.trim();
  if (NUMERIC_REGEX.test(trimmedValue) === false) {
    return 'Only 0-9 are allowed';
  }
  const codeLength = Number(data.codeLength);
  if (trimmedValue.length !== codeLength) {
    return `Must have ${codeLength} digits`;
  }
  return null;
}

type Props = {
  factorType: FactorType;
  subject: string; // masked mobile number or email address
  width?: 'full' | 'auto';
  name: string;
  label: string;
  codeLength: number;
  loading: boolean;
  state: string;
  onResendCodeClick: (evt: React.SyntheticEvent<HTMLAnchorElement>) => void;
};

export const BasisOneTimeCode: React.FC<Props> = ({
  subject,
  name = 'oneTimeCode',
  codeLength = 6,
  width = 'full',
  loading,
  state,
  onResendCodeClick,
  factorType,
}) => {
  return (
    <Stack gap="2">
      <Text textStyle="heading6">Enter your verification code</Text>
      <Text>
        We've just sent an {factorType} verification code to{' '}
        <strong>{factorType === 'sms' ? subject.replace(/[X*]/gi, '•') : subject}</strong>.
        <br />
        Please enter the {codeLength} digit code. The code will expire in 5 minutes.
      </Text>
      <Stack gap="2" width={width}>
        <div style={{ maxWidth: 300 }}>
          <OtpInput
            name={name}
            validate={validate}
            validateData={{ codeLength }}
            disabled={state === 'pending'}
            testId="verification-code"
            codeLength={Number(codeLength)}
            label=""
          />
        </div>
        <Container textStyle="body2">
          <Stack gap="2" direction="horizontal">
            <Text>Not received your code yet?</Text>
            {loading || state === 'pending' ? (
              <Text>
                <strong>Sending...</strong>
              </Text>
            ) : state === 'success' ? (
              <Text color="conditional.positive.text">
                <strong>Code sent</strong>
              </Text>
            ) : state === 'error' ? (
              <Text color="conditional.negative.text">
                <strong>Error sending, please wait...</strong>
              </Text>
            ) : (
              <ResendButton onClick={onResendCodeClick}>
                <strong>Resend code</strong>
              </ResendButton>
            )}
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
}

const ResendButton = styled.span`
  text-decoration: none;
  cursor: pointer;
  color: #0061ee;
  font-family: 'Roboto',sans-serif;
  font-weight: 500;
`;
