import React from 'react';
import { Text, Stack } from 'basis';
import { LayoutPage } from 'components';
import { Routes } from 'types';
import { config } from '_config';
import { Box, ChakraProvider } from '@chakra-ui/react';

export const Outcome: React.FC = () => {
  return (
    <ChakraProvider>
      <LayoutPage step="outcome" paddingBottom={0} hideProgressStepper>
        <Box padding={{ base: '24px 20px', md: '54px 20px' }} maxWidth="680px" margin="0 auto">
          <Stack gap="8">
            <Text as="h1" textStyle="heading5" textStyle-md="heading4" color="grey.t75">
              {config.routeConfig[Routes.outcome].pageTitle}
            </Text>
            <Text color="grey.t75">
              Thank you for submitting your documents. <br />
              We will review them and if required be in touch with you to discuss next steps.
            </Text>
          </Stack>
        </Box>
      </LayoutPage>
    </ChakraProvider>
  );
};
