import { State, DataLayer } from 'types';
import deepmerge from 'deepmerge';

type TrackData = {
  [key: string]: any;
};

type TrackingData = {
  [key: string]: TrackData;
};

export type TrackingEventData = {
  siteData: TrackingData;
  pageData: TrackingData;
  eventData: TrackingData;
};

// TODO: Get event data requirements https://di.latitudefinancial.com/jira/browse/NZP-720
export const getTrackingEventData = (state: State): DataLayer => {
  const elements = {
    application: {
      applicationChannel: 'Direct', // initially we will only have direct, but we need to default to "Direct"
      applicationOutcome: '', // <'Referred', 'Error'>
      applicationOutcomeReason: '',

      poi: {
        assessmentId: state.assessmentId, //Use assessmentID as ePOI ID to serialise against
        poiStartDate: state.tealium.poiStartDate, //Timestamp of when the Application was Started in ISO8601 date time format
        bankSelected: state.tealium.bankSelected, //Bank Selected
        numberOfAccountsSelected: state.tealium.numberOfAccountsSelected, //Number of Accounts Selected on ePOI Form to verify income
        numberOfTransactionsSelected: state.tealium.numberOfTransactionsSelected, //Number of Transactions Selected on ePOI Form to verify income
      },
    },
    applicant: {
      personal: {
        emailAddress: state.contactDetails.emailAddress, // set directly from field "confirmEmail" on page "About You". Should only be set if confirmEmail === emailAddress - This value will be hashed by Tealium
        mobileNumber: state.contactDetails.mobileNumber, // set directly from field "mobileNumber" on page "About You". This value will be hashed by Tealium
      },
    },
  };

  return elements;
};

const flattenData = (data: any, prefixParam?: string, resultParam?: any): TrackData => {
  const prefix = prefixParam || '';
  const result = resultParam || {};
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const lowerCaseKey = key.toLowerCase();
      if (typeof data[key] !== 'object' || data[key] instanceof Array) {
        result[prefix + lowerCaseKey] = data[key];
      } else {
        flattenData(data[key], `${prefix}${lowerCaseKey}_`, result);
      }
    }
  }
  return result;
};

export const getDataLayer = (allData: TrackingEventData) => {
  const { siteData, pageData, eventData } = allData;
  const data = deepmerge.all([siteData, pageData, eventData].filter(Boolean), {
    arrayMerge: (_destinationArray, sourceArray) => sourceArray,
  });

  return flattenData(data, '', {});
};
