import { useEffect } from 'react';
import { datadogRum, RumEvent } from '@datadog/browser-rum';

export const dataDogContext = {
  current: {
    assessmentId: '',
  },
};

export function updateDataDogContext(data: any) {
  dataDogContext.current = { ...dataDogContext.current, ...data };
}

export function beforeSend(event: RumEvent): void {
  // eslint-disable-next-line no-param-reassign
  event.context = { ...event.context, ...dataDogContext.current };
}

export const useDatadog = (): void => {
  useEffect(() => {
    datadogRum.init({
      applicationId: `${process.env.REACT_APP_DATADOG_APPLICATION_ID}`,
      clientToken: `${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
      env: `${process.env.REACT_APP_ENV}`,
      service: `${process.env.REACT_APP_SERVICE}`,
      version: `${process.env.REACT_APP_VERSION}`,
      sampleRate: 100,
      trackInteractions: false,
      beforeSend,
    });
  }, []);
};
