import { useState } from 'react';
import axios from 'axios';
import { useHandleApiErrors } from './useHandleApiErrors';
import {ApiError, ApiType, ErrorType} from 'types';
import { useAppContextSelector } from './useAppContext';
import { getAuthHeader } from 'utils';

type UseAcceptanceOffer = {
  loading: boolean;
  acceptanceOffer: () => Promise<void>;
};

type UseUseAcceptanceOfferArgs = {
  onSuccess?: (data: any) => void;
  onError?: (error: ApiError) => void;
};

export const useAcceptanceOffer = ({ onSuccess, onError }: UseUseAcceptanceOfferArgs = {}): UseAcceptanceOffer => {
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiErrors(ApiType.axios, onError);
  const { accessToken, correlationId } = useAppContextSelector((ctx) => ctx.state);

  const acceptanceOffer = async () => {
    const url = process.env.REACT_APP_INIT_API_URL as string;
    const body = {accepted: true};
    const options = {
      headers: {
        Authorization: getAuthHeader(accessToken),
        Accept: 'application/json',
      },
    };

    try {
      setLoading(true);

      await axios.post(`${url}/applications/${correlationId}/accept`, body, options);

      setLoading(false);

      const data = {};
      onSuccess && onSuccess(data);
    } catch (error) {
      setLoading(false);
      handleError({ type: ErrorType.AXIOS_NETWORK_ERROR, error });
    }
  };

  return { loading, acceptanceOffer };
};
