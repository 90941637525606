import { useEffect } from 'react';
import { usePageTracking } from 'react-event-tracker';
import { getTrackingEventData } from 'utils';
import { useAppContextSelector } from 'hooks';

type TrackPageViewArgs = {
  siteSubSection: string;
  pageTitle: string;
  isError?: boolean;
};

// TODO: Update config https://di.latitudefinancial.com/jira/browse/NZP-720

export const useTrackingPageView = ({ siteSubSection, pageTitle, isError }: TrackPageViewArgs): void => {
  const state = useAppContextSelector((ctx) => ctx.state);

  const { trackPageView } = usePageTracking(
    {
      page: {
        pageType: isError ? 'error-page' : 'funnel-page',
        siteSection: 'merchant-portal',
        siteSubSection,
        pageTitle,
      },
      product: {
        productId: ['CCNZGEM'],
      },
      ...getTrackingEventData(state),
    },
    {
      trackPageViewByDefault: false,
    },
  );

  useEffect(() => {
    trackPageView();
  }, [trackPageView, pageTitle]);
};
