import React from 'react';
import { Grid } from 'basis';
import { LayoutSection } from 'components';
import styled from 'theme/styled';

type Props = {
  children: React.ReactNode;
};

export const PageTitle: React.FC<Props> = ({ children }) => {
  return (
    <LayoutSection>
      <Grid rowsGap="8">
        <Title>{children}</Title>
      </Grid>
    </LayoutSection>
  );
};

export const Title = styled.h2`
  margin: 0;
  color: #000000;
  text-align: inherit;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 20px;
  }
`;
