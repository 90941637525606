import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useOktaClient } from 'hooks';
import { BasisOneTimeCode } from './BasisOneTimeCode';
import { ApiError, FactorType } from 'types';
import { FormData } from 'components';

type Props = {
  factorType: FactorType;
  subject: string; // masked mobile number or email address
  resendMsecs: number;
  onResendCodeClick?: () => void;
  onResendCodeError?: (error: ApiError) => void;
  formData?: FormData;
  name: string;
  label: string;
  codeLength: number;
};

export enum State {
  LOCKED = 'LOCKED',
  READY = 'READY',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const OneTimeCode: React.FC<Props> = ({
  resendMsecs,
  onResendCodeClick,
  onResendCodeError,
  formData,
  subject,
  factorType,
  name,
  label,
  codeLength,
}) => {
  const [state, setState] = useState(State.LOCKED);
  const requestAfterLock = useRef(false);
  const timeout = useRef<any>(null);

  const handleResendCodeSuccess = () => {
    setState(State.SUCCESS);
  };
  const handleResendCodeError = (error: ApiError) => {
    onResendCodeError && onResendCodeError(error);
    setState(State.ERROR);
  };
  const { loading, resendOTP } = useOktaClient({ onSuccess: handleResendCodeSuccess, onError: handleResendCodeError });

  const handleResendCodeClick = (evt: React.SyntheticEvent<HTMLAnchorElement>): void => {
    evt.preventDefault();
    onResendCodeClick && onResendCodeClick();
    formData?.resetForm({
      values: {
        verificationCode: '',
      },
      errors: {},
    });
    if (state === State.LOCKED) {
      requestAfterLock.current = true;
      setState(State.PENDING);
    } else {
      void resendOTP();
    }
  };

  const clearTimer = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = null;
  }, [timeout]);

  useEffect(() => {
    if (!timeout.current && (state === State.LOCKED || state === State.SUCCESS || state === State.ERROR)) {
      timeout.current = setTimeout(() => {
        setState(State.READY);
        clearTimer();
        if (requestAfterLock.current) {
          requestAfterLock.current = false;
          void resendOTP();
        }
      }, resendMsecs);
    }
  }, [state, resendMsecs, requestAfterLock, resendOTP, clearTimer]);

  useEffect(() => {
    return () => clearTimer();
  }, [clearTimer]);

  return (
    <BasisOneTimeCode
      factorType={factorType}
      subject={subject}
      name={name}
      label={label}
      codeLength={codeLength}
      state={state}
      loading={loading}
      onResendCodeClick={handleResendCodeClick}
    />
  );
};
