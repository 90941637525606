import { useState } from 'react';
import axios from 'axios';
import { useHandleApiErrors } from './useHandleApiErrors';
import { ApiError, ApiType } from 'types';

type UseGetUserInfo = {
  loading: boolean;
  getUserInfo: (accessToken: string) => Promise<{ mobile_phone?: string; error: boolean }>;
};

type UseGetUserInfoArgs = {
  onSuccess?: (data: any) => void;
  onError?: (error: ApiError) => void;
};

export const useGetUserInfo = ({ onSuccess, onError }: UseGetUserInfoArgs = {}): UseGetUserInfo => {
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiErrors(ApiType.axios, onError);

  const getUserInfo = async (accessToken: string): Promise<{ mobile_phone?: string; error: boolean }> => {
    const url = `${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/${process.env.REACT_APP_OKTA_ISSUER_ID}/v1/userinfo`;
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    try {
      setLoading(true);
      const result = await axios.get(url, options);
      setLoading(false);
      onSuccess && onSuccess(result.data);
      return { error: false, ...result.data };
    } catch (error) {
      setLoading(false);
      handleError(error);
      return { error: true };
    }
  };

  return { loading, getUserInfo };
};
