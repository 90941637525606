import { State } from 'types';

/**
 * Infer the source from the appNum. This is needed to support redirection for in-flight applications with email links to the legacy NTT doc uploader.
 * In these cases, the source is not passed in the URL, so that we need to infer it from the appNum.
 *
 * Rules:
 * - if no appNum, default to smp-nz (as Income LFS was only used by SMP NZ prior to 2024 Dec)
 * - if appNum is 16 digits, this is a account number. Return cli-nz
 * - if appNum is 10 digits, this is an application ID. Then get the prefix of the ID and infer the source from that.
 *
 * Hopefully, we won't need source inference once migration graceful period is complete by March 2025.
 *
 * @param appNum - The appNum URL query param.
 * @returns The source.
 */
export function inferSource(appNum?: string): State['source'] {
  if (!appNum) {
    return 'smp-nz';
  }

  if (appNum.startsWith('f')) {
    return 'smp-au';
  }

  if (appNum.length === 16) {
    return 'cli-nz';
  }

  const applicationIdPrefix = appNum.slice(0, 3);
  return prefixMappings[applicationIdPrefix as keyof typeof prefixMappings] || 'smp-nz';
}

const prefixMappings: Record<string, State['source']> = {
  '241': 'smp-au',
  '251': 'apply-nz',
  '500': 'cli-au',
  '990': 'smp-au',
};
