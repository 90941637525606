import React from 'react';
import { Container, Button, Text, Link } from 'basis';
import styled from '@emotion/styled';
import { media } from 'theme';

const BannerTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

const ImageIcons = styled.img<{ backgroundColor?: string }>`
  height: 60px;
  margin-right: 16px;
  @media ${media.laptop} {
    background-color: ${(p) => p.backgroundColor};
  }
`;

const s = {
  BannerTitleContainer,
  ImageIcons,
};

type Props = {
  icon: string;
  title: string;
  linkHref?: string;
  linkLabel?: string;
  onClick?: (evt: React.SyntheticEvent<HTMLAnchorElement>) => void;
  testId?: string;
  children: React.ReactNode;
  newTab?: boolean;
};

export const InformationCard: React.FC<Props> = ({
  icon,
  title,
  linkHref,
  linkLabel,
  onClick,
  testId,
  children,
  newTab = false,
}) => {
  const hasLink = linkLabel && (linkHref || onClick);
  return (
    <Container padding="3 0" testId={testId}>
      <Container bg="secondary.lightBlue.t15" hasBorder-md padding="4" padding-md="8">
        <s.BannerTitleContainer>
          <s.ImageIcons src={icon} alt="" />
          <Text textStyle="heading6">{title}</Text>
        </s.BannerTitleContainer>

        <Container>{children}</Container>

        {hasLink && (
          <Container margin="8 0 0 0">
            {linkHref ? (
              <Link
                onClick={onClick}
                width="100%"
                width-xs="100%"
                width-sm="252px"
                appearance="secondary-button"
                variant="blue-button"
                href={linkHref}
                newTab={newTab}
              >
                {linkLabel}
              </Link>
            ) : (
              <Button
                onClick={onClick}
                width="100%"
                width-xs="100%"
                width-sm="252px"
                variant="secondary"
                color="highlight.blue.t100"
              >
                {linkLabel}
              </Button>
            )}
          </Container>
        )}
      </Container>
    </Container>
  );
};
