import { useCallback } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

const TextButton = styled.b`
  color: #0061ee;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
`;


export function BackNavigator() {
  const history = useHistory();
  const onBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <TextButton data-testid="back-navigator" onClick={onBackClick}>
      <ChevronLeftIcon />
      <span>Back</span>
    </TextButton>
  );
}

type ChevronLeftIconProps = {
} & React.ComponentPropsWithoutRef<typeof Box>;

const ChevronLeftIcon = (props: ChevronLeftIconProps) => (
  <Box display="inline-block" lineHeight={1} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="18"
      viewBox="0 0 9 18"
      fill="none"
      style={{ display: 'block' }}
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.71056 15.3302C9.09917 15.7226 9.09607 16.3558 8.70363 16.7444C8.3112 17.133 7.67804 17.1299 7.28944 16.7374L0.289435 9.66848C-0.0978236 9.2774 -0.0962779 8.64691 0.292893 8.25774L7.29289 1.25774C7.68342 0.867213 8.31658 0.867213 8.70711 1.25774C9.09763 1.64826 9.09763 2.28143 8.70711 2.67195L2.41076 8.9683L8.71056 15.3302Z" fill="#0061EE"/>
    </svg>
  </Box>
);
