import React from 'react';
import { Header as BasisHeader } from 'basis';
import { useAppContext } from 'hooks';
import { byCountry } from 'utils';

export const Header: React.FC = () => {
  const { state } = useAppContext();

  return (
    <BasisHeader>
      <BasisHeader.Logo name={byCountry(state, { nz: 'gem', au: 'latitude' })} />
    </BasisHeader>
  );
};

// @ts-ignore
Header.ID = 'MyHeader';
// @ts-ignore
Header.HEIGHT_MAP = BasisHeader.HEIGHT_MAP;
