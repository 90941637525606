import { getSessionStorage } from 'utils/sessionStorage';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';

const DEFAULT_FEATURES = {
  UPLOAD: false,
};
export type Features = typeof DEFAULT_FEATURES;
export type FeatureKey = keyof typeof DEFAULT_FEATURES;

export const featuresStorageKey = 'features';

export const getFeatures = (): Features => {
  if (process.env.REACT_APP_ENV === 'prod') {
    return DEFAULT_FEATURES;
  }
  const storageFeatures = getSessionStorage(featuresStorageKey) || {};
  const enabledFeatures = mapValues(DEFAULT_FEATURES, () => true);
  return merge({}, enabledFeatures, storageFeatures);
};

export const isFeatureOn = (key: FeatureKey): boolean => {
  const features = getFeatures();
  if (features.hasOwnProperty(key)) {
    return features[key];
  }
  return false;
};
