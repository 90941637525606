import { useAppContext } from "hooks";
import { byCountry } from "utils";
import { NzFooter } from "./NzFooter";
import { AuFooter } from "./AuFooter";

export function Footer() {
  const { state } = useAppContext();
  const FooterComponent = byCountry(state, { nz: () => NzFooter, au: () => AuFooter });
  return <FooterComponent />;
}
