import { Input, Select, List } from 'basis';
import { useAppContext } from 'hooks/useAppContext';
import { validation } from 'utils/validation';
import { FormData } from 'components';

const validateReferenceField = validation(
  { validPattern: /^\d{13}$|^\d{16}$/ },
  {
    invalid: 'Enter your 13 digit application number or 16 digit account number',
    required: 'Enter your 13 digit application number or 16 digit account number',
  }
)[0];

export function ReferenceField() {
  const { state } = useAppContext();

  const shouldShow = [undefined, null, ''].includes(state.appNum);

  if (!shouldShow) {
    return null;
  }

  return <Input
    name="reference"
    maxLength={20}
    label={<strong>Enter your application or account number</strong>}
    helpText="Enter your 13 digit application number or 16 digit account number"
    validate={validateReferenceField}
  />;
}

export function DocumentTypeField({ formData }: { formData: FormData }) {
  const { state } = useAppContext();

  const shouldShow = ['website-au', 'website-nz'].includes(state.source);

  if (!shouldShow) {
    return null;
  }

  return <>
    <Select
      name="documentType"
      label={<strong>Select your document type</strong>}
      placeholder="Please select your document type"
      options={documentTypeOptions}
    />
    {formData.state?.values?.documentType === 'OTHER' && <OtherDocumentTypes />}
  </>
}

export type DocumentTypeInput = 'PROOF_OF_INCOME' | 'PROOF_OF_ADDRESS' | 'OTHER';

const documentTypeOptions: { label: string; value: DocumentTypeInput }[] = [
  { label: 'Proof of Income', value: 'PROOF_OF_INCOME' },
  { label: 'Proof of Address / Identity', value: 'PROOF_OF_ADDRESS' },
  { label: 'Other', value: 'OTHER' },
];

export function OtherDocumentTypes() {
  return <List>
    <List.Item>Name change document</List.Item>
    <List.Item>Address change document</List.Item>
    <List.Item>Direct debit</List.Item>
    <List.Item>Proof of payment</List.Item>
    <List.Item>Death certificates and correspondence</List.Item>
    <List.Item>Other servicing documents</List.Item>
  </List>
}
