import { useState } from 'react';
import axios from 'axios';
import { DEV_FEATURES } from '__dev';
import { useAppContext } from './useAppContext';
import { useOktaClient } from './useOktaClient';
import { useHandleApiErrors } from './useHandleApiErrors';
import { ApiError, ApiType, FactorType } from 'types';
import { setVerification } from 'context';
import { formatMaskedMobileNumber, toInternationalMobileNumber } from 'utils';
import { datadogRum } from '@datadog/browser-rum';

const registerURL = process.env.REACT_APP_USER_REGISTER_URL as string;

type SubmitArgs = { email: string; mobile: string };

type UseRegisterAndLoginUser = {
  loading: boolean;
  submit: ({ email, mobile }: SubmitArgs) => Promise<void>;
};

type UseRegisterAndLoginUserArgs = {
  onSuccess?: (props: { factorType: FactorType }) => void;
  onError?: (error: ApiError) => void;
};

export const useRegisterAndLoginUser = ({
  onSuccess,
  onError,
}: UseRegisterAndLoginUserArgs): UseRegisterAndLoginUser => {
  const { dispatch, state, updateState } = useAppContext();
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiErrors(ApiType.axios, onError);
  const { loginAndSendOTP } = useOktaClient({ onError });

  const submit = async ({ email, mobile }: SubmitArgs) => {
    const mobilePhone = DEV_FEATURES.OTP_MOBILE_NUMBER || toInternationalMobileNumber(mobile, state.countryCode);
    const payload = {
      profile: {
        email,
        mobile_phone: mobilePhone,
      },
    };

    const options = {
      headers: {
        'x-correlation-id': state.assessmentId,
        'lfs-request-channel': process.env.REACT_APP_OKTA_CLIENT_ID,
      },
    };

    try {
      setLoading(true);

      const registerData = await axios.post(registerURL, payload, options);

      if (registerData?.data?.profile?.masked_mobile_phone) {
        dispatch(
          setVerification({
            maskedMobileNumber: formatMaskedMobileNumber(
              registerData.data.profile.masked_mobile_phone,
              state.countryCode,
            ),
          }),
        );

        const factorType = 'sms';

        const success = await loginAndSendOTP({ email, factorType });

        success && onSuccess && onSuccess({ factorType });
      } else {
        // Okta profile `registerData.data.profile` is an empty object `{}`, so fallback to login via email
        const factorType = 'email';

        const error: CustomError = new Error(`Okta user profile is empty {}. Email: ${email}. App number: ${state.appNum}.`);
        error.kind = 'system-error';
        error.fingerprint = `OKTA_EMPTY_MOBILE_NUMBER::email::${email}`;
        error.type = 'OKTA_EMPTY_MOBILE_NUMBER_ERROR';
        datadogRum.addError(error);

        updateState({
          error: null,
          verification: {
            oktaProfileHealthy: false,
          },
        });

        const success = await loginAndSendOTP({ email, factorType });
        success && onSuccess && onSuccess({ factorType });
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    submit,
  };
};

interface CustomError extends Error {
  kind?: 'system-error' | 'user-error';
  fingerprint?: string;
  type?: 'OKTA_EMPTY_MOBILE_NUMBER_ERROR';
}
