import React, {useMemo} from 'react';
import {Box, ChakraProvider, Flex, Icon, Text as ChText, VStack} from '@chakra-ui/react';
import {Text} from 'basis';
import styled from '@emotion/styled';
import {TimeIcon} from '@chakra-ui/icons';
import {ChevronRightIcon, LfsLockIcon, MoneyDocumentsIcon, MoneyEnvelopeIcon} from 'components/icons';
import {CountryCode, State} from 'types';
import {isWebsiteSourced} from 'utils';

type OnClick = (event: React.MouseEvent<HTMLDivElement>) => void;

export type MethodsProps = {
  showBankConnectOption: boolean;
  onClickBankConnect: OnClick;
  onClickPayslipUpload: OnClick;
  onClickDocUpload: OnClick;
  state: State;
};

export function Methods({
  state,
  showBankConnectOption,
  onClickBankConnect,
  onClickPayslipUpload,
  onClickDocUpload,
}: MethodsProps) {
  const methods = useMemo(
    () => [
      {
        icon: <LfsLockIcon />,
        title: 'Online Bank Connect',
        description:
          "Allow one-time access to your bank statements. It's secure, easy and your login details are never stored.",
        badge: 'Fast response',
        badgeColor: '#e8f4ff',
        onClick: onClickBankConnect,
        shouldShow: showBankConnectOption,
        testId: 'verify_online',
      },
      {
        icon: <MoneyEnvelopeIcon size={34} />,
        title: 'Upload payslips',
        description: 'Payslips cannot be older than 60 days.',
        badge: isWebsiteSourced(state) || state.countryCode === CountryCode.NZ ? undefined : 'Fast response',
        badgeColor: '#e8f4ff',
        onClick: onClickPayslipUpload,
        testId: 'upload_payslips',
      },
      {
        icon: <MoneyDocumentsIcon width={35} height={44} />,
        title: 'Upload bank statements / other documents',
        description: isWebsiteSourced(state)
          ? 'Upload one or multiple documents to support your card application and/or servicing requests.'
          : 'Upload one or multiple documents to verify your income such as bank statements, tax returns or letters from your accountant or employer.',
        badgeColor: '#E5E5E5',
        onClick: onClickDocUpload,
        testId: 'upload_your_documents',
      },
    ],
    [onClickBankConnect, onClickDocUpload, onClickPayslipUpload, showBankConnectOption, state],
  );

  return (
    <ChakraProvider>
      {/* @ts-ignore */}
      <VStack spacing="0" marginTop="24px">
        {methods.map((method) => method.shouldShow !== false && <Method key={method.title} {...method} />)}
      </VStack>
    </ChakraProvider>
  );
}

type MethodProps = {
  title: string;
  description: string;
  badge?: string;
  icon: React.ReactNode;
  badgeColor: string;
  onClick: OnClick;
  testId: string;
};

function Method({ title, description, badge, icon, badgeColor, onClick, testId }: MethodProps) {
  return (
    <MethodButton onClick={onClick} padding={{ base: '16px 0px', md: '24px' }} data-testid={testId}>
      <Flex alignItems={{ base: 'flex-start', sm: 'center' }} width="100%">
        <MethodIconWrapper padding={{ base: '4px 0px 4px 8px', md: '0px 10px 12px 0px' }}>{icon}</MethodIconWrapper>

        <Box flex="1" marginRight="6">
          <Box position="relative" mb={1}>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              alignItems={{ base: 'flex-start', md: 'center' }}
              justifyContent="space-between"
              mb={1}
            >
              <ChText
                fontFamily="Montserrat, Roboto, sans-serif"
                fontSize="16px"
                fontWeight="500"
                flex="1"
                marginRight={4}
                marginBottom={{ base: 2, md: 0 }}
              >
                {title}
              </ChText>
              {badge && (
                <Badge flexShrink={0} backgroundColor={badgeColor} marginBottom={{ base: '8px', md: '4px' }}>
                  <Icon as={TimeIcon} marginRight="2" />
                  <ChText fontSize="14px" fontWeight="500" whiteSpace="nowrap">
                    {badge}
                  </ChText>
                </Badge>
              )}
            </Flex>
          </Box>
          <Text textStyle="body2">{description}</Text>
        </Box>

        <ChevronRightIcon alignSelf="center" />
      </Flex>
    </MethodButton>
  );
}

const MethodButton = styled(Box)`
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #f7fafc;
  }

  border-bottom: 1px solid #e2e8f0;
  &:first-of-type {
    border-top: 1px solid #e2e8f0;
  }
`;

const Badge = styled(Flex as React.ComponentType<any>)`
  padding: 4px 12px;
  border-radius: 16px;
  align-items: center;
  height: 24px;
`;

const MethodIconWrapper = styled(Box)`
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;
