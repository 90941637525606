import { getSessionStorage } from 'utils/sessionStorage';
import merge from 'lodash/merge';

const DEFAULT_DEV_FEATURES = {
  ENV_OVERRIDES: {},
  OTP_MOBILE_NUMBER: '',
  OTP_GENERATE_UNIQUE_EMAIL: false,
  OTP_SKIP: false,
  ALLOW_NAVIGATION_TO_ALL_ROUTES: false,
  SHOW_FORM_DEBUG: false,
  PREVENT_UNLOAD_MESSAGE: true,
  HIDE_DEV_WIDGETS: false,
};

export const devFeaturesStorageKey = 'devFeatures';

const storageFeatures = getSessionStorage(devFeaturesStorageKey);

export const DEV_FEATURES = storageFeatures ? merge({}, DEFAULT_DEV_FEATURES, storageFeatures) : DEFAULT_DEV_FEATURES;
