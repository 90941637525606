import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Stack } from 'basis';
import { useAppContext, useTrackingEvent } from 'hooks';
import { Method, Routes } from 'types';
import { setError, setMethod, setTealium } from 'context';
import { LayoutPage, LayoutSection } from 'components';

import { Methods } from './Methods';
import { isPoaSupported } from 'utils';

export const mainConfig = {
  FI_CONNECT: {
    title: 'Share your bank statement',
    label: 'Share bank statement',
    modalLinkLabel: 'Safely and securely verify your income.',
  },
  DOCUMENTS: { title: 'Upload other documents', label: 'Upload documents', modalLinkLabel: 'Find out more' },
  PAYSLIPS: { title: 'Upload your payslips', label: 'Upload payslips', modalLinkLabel: 'Find out more' },
};

export const Main: React.FC = () => {
  const history = useHistory();
  const { trackEvent } = useTrackingEvent();
  const { state, dispatch } = useAppContext();

  const showBankConnectOption = !!state.assessmentId;

  useEffect(() => {
    dispatch(setError(null));
    // eslint-disable-next-line
  }, []);

  const handleBankConnectClick = (evt: React.MouseEvent<HTMLDivElement>): void => {
    evt.preventDefault();

    dispatch(
      setTealium({
        poiStartDate: new Date().toISOString(),
      }),
    );

    trackEvent({
      category: 'application',
      action: 'application-navigation',
      location: mainConfig.FI_CONNECT.title,
      label: mainConfig.FI_CONNECT.label,
    });

    history.push(Routes.connect);
  };

  const handleUploadLinkClick = (type: Method): void => {
    trackEvent({
      category: 'text-link',
      action: 'internal-link',
      location: mainConfig[type].title,
      label: mainConfig[type].label,
    });
    dispatch(setMethod(type));
    history.push(Routes.upload);
  };

  const subject = isPoaSupported(state) ? 'documents' : 'income';

  return (
    <LayoutPage step="update">
      <LayoutSection>
        <Stack gap="8">
          <Text textStyle="heading5">Verify your {subject}</Text>
          {isPoaSupported(state) && (
            [
              <Text>Please select <strong>one</strong> of the following options.</Text>,
            ]
          )}
          {!isPoaSupported(state) && (
            [
              <Text>You're almost there! We just need to verify a few things before we finalise your application.</Text>,
              <Text>Please select <strong>one</strong> of the following options to verify your income.</Text>
            ]
          )}
        </Stack>
        <Methods
          state={state}
          showBankConnectOption={showBankConnectOption}
          onClickBankConnect={handleBankConnectClick}
          onClickPayslipUpload={() => handleUploadLinkClick('PAYSLIPS')}
          onClickDocUpload={() => handleUploadLinkClick('DOCUMENTS')}
        />
      </LayoutSection>
    </LayoutPage>
  );
};
