import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useEventTracking } from 'react-event-tracker';
import { getTrackingEventData } from 'utils';
import { config } from '_config';
import { Routes } from 'types';
import { useAppContextSelector } from './useAppContext';
import { datadogRum } from '@datadog/browser-rum';

type TrackErrorArgs = {
  error: any;
  location?: string;
  label?: string;
};

type UseTrackingError = {
  trackError: ({ error, location, label }: TrackErrorArgs) => void;
};

export const useTrackingError = (): UseTrackingError => {
  const state = useAppContextSelector((ctx) => ctx.state);
  const { pathname } = useLocation();
  const { trackEvent } = useEventTracking();

  const trackError = useCallback(
    ({ error, location, label }: TrackErrorArgs) => {
      const errorLocation = location || config.routeConfig[pathname as Routes].pageTitle;
      const errorLabel = label || error?.error?.message || error.toString() || 'Request Error';
      trackEvent({
        event: {
          category: 'application',
          action: 'application-error',
          location: errorLocation,
          label: errorLabel,
        },
        ...getTrackingEventData({ ...state, error }),
      });
      datadogRum.addError(error, undefined);
    },
    [state, pathname, trackEvent],
  );

  return { trackError };
};
