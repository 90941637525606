import React from 'react';
import styled from '@emotion/styled';
import { Container, Grid } from 'basis';

const Section = styled.div`
  & > div {
    margin: 0 auto;
  }
`;

const Content = styled.div<{ position?: string }>`
  position: ${(p) => p.position ?? 'initial'};
`;

const s = {
  Section,
  Content,
};

type Props = {
  children: React.ReactNode;
  paddingTop?: number;
  position?: string;
};

export const LayoutSection: React.FC<Props> = ({ children, paddingTop, position }) => {
  return (
    <s.Section data-id="LayoutSection">
      <Container hasBreakpointWidth width="100%" padding={`${paddingTop ?? 6} 4 0 4`} padding-md={`${paddingTop ?? 8} 0 0 0`}>
        <Grid preset="page">
          <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="1-6" colSpan-lg="2-9" colSpan-xl="3-8">
            <s.Content data-id="LayoutSectionContent" position={position}>
              {children}
            </s.Content>
          </Grid.Item>
        </Grid>
      </Container>
    </s.Section>
  );
};
