import { Text, List, Link } from 'basis';
import { byCountry } from 'utils';
import { State } from 'types';
import { FormData } from 'components';
import { IconWrapper } from './styles';
import { DocUpload, UploadMethods } from 'doc-upload';
import { toDocUploadProps } from './toDocUploadProps';
import { SUPPORTED_DOC_LIST_URLS } from './utils';
import { ReferenceField } from './Fields';
import { MoneyEnvelopeIcon } from 'components/icons';
import { Title } from 'components/page-title/PageTitle';

type UploadPayslipsProps = { state: State, formData: FormData, methodsRef: React.MutableRefObject<UploadMethods | undefined> };
export function UploadPayslips(props: UploadPayslipsProps) {
  return byCountry(props.state, {
    au: <UploadPayslipsAU {...props} />,
    nz: <UploadPayslipsNZ {...props} />,
  });
}

function UploadPayslipsAU({ state, formData, methodsRef }: UploadPayslipsProps) {
  return (
    <>
      <Title>Upload your payslips</Title>
      <IconWrapper>
        <MoneyEnvelopeIcon size={48} />
        <Text>
          For a quick response, please upload a clear legible copy of your payslip(s).
        </Text>
      </IconWrapper>
      <ReferenceField />
      <div>
        <Text>
          <strong>All documents must contain your full legal name.</strong>
        </Text>
        <br />
        <List>
          <List.Item>Is a PDF, screenshot or photo of a full computer-generated payslip</List.Item>
          <List.Item>Shows your employer's name or ACN/ABN</List.Item>
          <List.Item>Most recent payment is dated within the last 31 days </List.Item>
          <List.Item>Includes <strong>minimum 2 months of year to date</strong> income or <strong>supply 2 consecutive payslips</strong></List.Item>
          <List.Item>Shows net/gross income and frequency of pay </List.Item>
          <List.Item>If employed by a family member we may need an additional document such as a bank statement or accountant letter</List.Item>
        </List>
      </div>
      <Text>
        View our <Link href={SUPPORTED_DOC_LIST_URLS.au} newTab>list of acceptable proof of income documents</Link>.
      </Text>
      <DocUpload {...toDocUploadProps({ state, formData, methodsRef })} />
    </>
  );
}

function UploadPayslipsNZ({ state, formData, methodsRef }: UploadPayslipsProps) {
  return (
    <>
      <Title>Upload your payslips</Title>
      <IconWrapper>
        <MoneyEnvelopeIcon size={48} />
        <Text>
          For a quick response, please upload a clear legible copy of your payslip(s).
        </Text>
      </IconWrapper>
      <ReferenceField />
      <div>
        <Text>
          <strong>All documents must contain your full legal name.</strong>
        </Text>
        <br />
        <List>
          <List.Item>Is a PDF, screenshot or photo of a full computer-generated payslip</List.Item>
          <List.Item>Shows your full name</List.Item>
          <List.Item>Your employer's name</List.Item>
          <List.Item>Most recent payment is dated within the last 31 days</List.Item>
          <List.Item>Includes <strong>minimum 2 months of year to date</strong> income or <strong>supply 2 consecutive payslips</strong></List.Item>
          <List.Item>Shows either Net or Gross income and frequency of pay</List.Item>
        </List>
      </div>
      <Text>
        View our <Link href={SUPPORTED_DOC_LIST_URLS.nz} newTab>list of supporting documentation</Link>.
      </Text>
      <DocUpload {...toDocUploadProps({ state, formData, methodsRef })} />
    </>
  );
}
