import { replaceStringVars } from './replaceStringVars';

export type Messages = {
  [key: string]: string;
};

export type Vars = {
  [key: string]: string | number | undefined;
};

export const initMessagesObject = (messages: Messages, vars?: Vars): Messages => {
  if (!vars) {
    return messages || {};
  }
  return Object.keys(messages).reduce((acc, key) => {
    return {
      ...acc,
      [key]: replaceStringVars(messages[key], vars),
    };
  }, {});
};
