import { useCallback } from 'react';
import { getSessionStorage } from 'utils';
import { devFeaturesStorageKey } from '__dev';

type EnvObject = { [key:string]: string }

export const useEnv = () => {
  return useCallback(getEnv, []);
};

export type GetEnv = typeof getEnv;
export function getEnv(): EnvObject;
export function getEnv(envName: string): string | undefined;
export function getEnv(envName?: string): string | EnvObject | undefined {
  if (!envName) {
    if (process.env.REACT_APP_ENV === 'prod') {
      return process.env as EnvObject;
    }
    return { ...process.env, ...getSessionStorage(devFeaturesStorageKey, 'ENV_OVERRIDES') };
  }

  if (process.env.REACT_APP_ENV === 'prod') {
    return process.env[envName] as string;
  }
  return getSessionStorage(devFeaturesStorageKey, 'ENV_OVERRIDES')?.[envName] || process.env[envName] as string;
}
