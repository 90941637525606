import React from 'react';
import {Text, Stack } from 'basis';
import {LayoutPage, LayoutOutcomePage} from 'components';
import { Routes } from 'types';
import { useTheme } from 'theme';
import { config } from '_config';
import {useAppContext} from "../../hooks";

export const AcceptanceConfirmation: React.FC = () => {
  const theme = useTheme();
  const { state } = useAppContext();
  const email = state.contactDetails.emailAddress;
  return (
    <LayoutPage step="outcome" paddingBottom={0}>
      <LayoutOutcomePage useConfetti>
        <Stack gap="8">
          <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
            {config.routeConfig[Routes.acceptanceConfirmation].pageTitle}
          </Text>
          <Text>
            We've sent you an email to {email} with your Initial Disclosure Statement, Conditions of Use and account details.
            You'll need to keep these documents for future reference.
          </Text>
        </Stack>
      </LayoutOutcomePage>
    </LayoutPage>
  );
};
